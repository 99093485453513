const articles = [
  {
    id: "65bc0f0ee4d4db001510f661",
    coverImage: {
      source:
        "https://d3us9uuazw4ws8.cloudfront.net/awards/shutterstock_2120043011+-+William+Fletcher+_+WorkL.jpg",
      caption: ""
    },
    categories: [""],
    promote: false,
    type: "article",
    title: "Leveraging Starter and Exit Employee Surveys",
    summary: "",
    content: [
      {
        caption: "",
        body: "Written by: Lord Mark Price",
        id: "5f1ffed193f42f081761bf45",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Employee feedback is invaluable for organisations seeking to enhance their workplace culture, improve employee satisfaction, and reduce turnover. Two critical moments in the employee lifecycle where feedback can provide significant insights are during the onboarding process (starter surveys) and upon an employee's departure (exit surveys). ",
        id: "5f6ffed193f42f081761bf673",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The employee engagement platform that I founded over five years ago, WorkL, offers specialist starter and exit surveys that help organisations identify early concerns so they can improve these and retain employees to avoid unnecessary turnover.",
        id: "5f6ffed193f42f081761bf72",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Some tips for onboarding new employees include;",
        type: "paragraph"
      },
      {
        caption: "",
        body: "1. Conduct starter surveys shortly after new employees join the organisation, ideally within their first few weeks. This allows you to capture fresh perspectives and address any early concerns or challenges before they escalate.",
        id: "5f6ffed193f42f001768bf8z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "2. Tailor survey questions to assess the effectiveness of the onboarding process, including the clarity of expectations, access to resources, integration into the team, and overall satisfaction with the orientation programme.",
        type: "paragraph"
      },
      {
        caption: "",
        body: "3. Assure new employees of the confidentiality of their responses to encourage honest feedback. Our surveys are confidential and taken on our digital platform.",
        type: "paragraph"
      },
      {
        caption: "",
        body: "4. Our survey results are analysed promptly and identify common themes or recurring issues. Use this feedback to refine your onboarding processes, address improvements, and enhance the overall new hire experience. Communicate any changes or improvements based on employee feedback to demonstrate a commitment to continuous improvement.",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Tips for exit surveys include;",
        type: "paragraph"
      },
      {
        caption: "",
        body: "1. Schedule exit interviews or surveys with departing employees to gain insights into their reasons for leaving, their overall experience with the organization, and areas for improvement. Conduct these surveys in a timely manner to capture feedback while it's still fresh in the employee's mind.",
        type: "paragraph"
      },
      {
        caption: "",
        body: "2. In addition to structured survey questions, include open-ended questions that allow departing employees to provide detailed feedback and insights into their experiences, challenges, and suggestions for improvement. This qualitative data can offer valuable context and uncover issues that may not have been captured by quantitative metrics alone.",
        type: "paragraph"
      },
      {
        caption: "",
        body: "3. Assure departing employees that their feedback will be treated confidentially and used constructively to drive positive change within the organisation. ",
        type: "paragraph"
      },
      {
        caption: "",
        body: "4. Analyse exit survey data to identify trends, patterns, and areas for improvement. Use this feedback to implement changes aimed at addressing underlying issues, improving retention, and enhancing the employee experience for current and future employees.",
        type: "paragraph"
      },
      {
        caption: "",
        body: "By implementing best practices for conducting starter and exit employee surveys, organisations can gain valuable insights into their employees' experiences, perceptions, and needs at critical stages. Leveraging this feedback to drive improvement helps to create a culture of listening, learning, and adaptation that ultimately contributes to greater employee satisfaction and employee retention.",
        id: "5f6ffed193f42f001768bf8x",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d80",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2024-07-09T09:32:49.210Z",
    updatedAt: "2024-07-09T09:32:49.216Z",
    createdAt: "2024-07-09T09:32:49.216Z"
  },

  {
    id: "65bc0f0ee4d4db001510f660",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/Mind-Logo.png",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "Creating Mentally Healthy Workplaces",
    summary: "",
    content: [
      {
        caption: "",
        body: "Written by: Andrew Berrie, Head of Workplace Wellbeing at Mind:",
        id: "5f1ffed193f42f081761bf45",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Creating mentally healthy workplaces should be high on every employer’s agenda, as it benefits the entire workforce, not just disabled staff and employees with mental health problems. It also saves employers money, with recent data from Deloitte UK finding a return of £5.30 for every £1 invested in workplace wellbeing interventions.",
        id: "5f6ffed193f42f081761bf673",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Mind’s Workplace Wellbeing Index has found that across all sectors, 24% of people find that their workload is unmanageable, while 17% of staff are working 50 or more hours a week. People Management’s study shows that HR professionals are well above the national average and that is clearly concerning. It’s not surprising that people’s mental health is being impacted by working in such conditions.",
        id: "5f6ffed193f42f081761bf72",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Workplace stress has a real impact on people’s mental health and wellbeing. Pressure can be helpful in the short term – it can help us focus and produce our best work. But over a long period it can lead to exhaustion and burnout.",
        type: "paragraph"
      },
      {
        caption: "",
        body: "There are many ways organisations can support their employees to help protect them from this – and one of the most important things is a strong relationship between line manager and employee. Person centred conversations are key, as everyone has different stress thresholds and triggers that can harm their mental health. Discussions on deadlines, quality standards and reflective priorities of different pieces of work can make a real difference.",
        id: "5f6ffed193f42f001768bf8z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Line management conversations can also address what support an employee might need. Mind’s free Wellness Action Plans, available from our website, allow individuals to highlight what they need to stay well.",
        id: "5f6ffed193f42f001768bf8y",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Finally, team or organisation leaders should look at creating or revising their stress risk assessments. Employers with 5 or more employees have a statutory duty to conduct and hold a written stress risk assessment. A large number of employers don’t realise this and HSE’s Working Minds campaign is currently seeking to raise understanding of this requirement amongst SMEs. On their website you’ll find a range of tools and sector specific articles on how to implement best practice in your organisation",
        id: "5f6ffed193f42f001768bf8x",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },

  {
    id: "65bc0f0ee4d4db001510f460",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/shutterstock_1772913758 -.jpg",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "Employee magnetism: Three ways to futureproof employee experience",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: https://hrzone.com/employee-magnetism-three-ways-to-futureproof-employee-experience/",
        id: "5f1ffed193f42f081761bf45",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Written by: Lucy Cox, Business Psychologist Consultant, OE Cam",
        id: "5f6ffed193f42f081761bf673",
        type: "paragraph"
      },
      {
        caption: "",
        body: "A well-defined employee experience (EX) is widely accepted as a staple requirement in HR’s toolkit. However, post-pandemic, there’s a growing sense that the employer/employee power dynamic has dramatically shifted in favour of the individual.",
        id: "5f6ffed193f42f081761bf72",
        type: "paragraph"
      },
      {
        caption: "",
        body: "So, coupled with ongoing societal change, organisations need to consciously design a truly magnetic EX if they want to attract and retain the best talent.",
        id: "5f6ffed193f42f081761bf71",
        type: "paragraph"
      },
      {
        caption: "",
        body: "But employee experience is a huge area – how can HR identify the critical moments that matter most to employees and design an EX that is future-proof?",
        id: "5f6ffed193f42f001768bf8z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Here, we share three trends for the future of work that, if acted on, will create a compelling EX. One that fulfils a stronger desire for purpose and meaning at work; evolving expectations around trust; and a greater need for human connection in an increasingly digital world.",
        id: "5f6ffed193f42f001768bf8y",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Let’s look at each in turn.",
        id: "5f6ffed193f42f001768bf8x",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Trend one: Purpose is now a must-have, not a nice-to-have",
        id: "5f6ffed193f42f001867bf8w",
        type: "paragraph"
      },
      {
        caption: "",
        body: "‘Lack of meaningful work’ was recently cited by McKinsey & Company in the top five reasons for leaving among people who quit their job without another lined up, ranking even higher than compensation or flexibility. While purpose has always been important, Deloitte data suggests that younger generations are even more likely to join or quit an organisation based on it.",
        id: "5f6ffed193f42f001761bf8v",
        type: "paragraph"
      },
      {
        caption: "",
        body: "An organisation’s purpose is why it exists; from Netflix who ‘want to entertain the world’, to Patagonia who are ‘in business to save our home planet’. Research by Kings College London identified four things that make your purpose statement effective:",
        id: "5f6ffed193f42f001761bf8u",
        type: "paragraph"
      },
      {
        caption: "",
        body: "1. Is it inspiring?",
        id: "5f6ffed193f42f001761bf8t",
        type: "paragraph"
      },
      {
        caption: "",
        body: "2. Is it well-written?",
        id: "5f6ffed193f42f001761bf8s",
        type: "paragraph"
      },
      {
        caption: "",
        body: "3. Is it authentic?",
        id: "5f6ffed193f42f701761bf8r",
        type: "paragraph"
      },
      {
        caption: "",
        body: "4. Is it plausible?",
        id: "5f6ffed193f42f008761bf8q",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The next step is to embed it throughout the organisation. By considering purpose at every step of the employee journey – from how you attract and recruit talent to how you develop, promote and retain them – you can ensure your organisation is full of people who feel connected to, and driven by, your purpose.",
        id: "5f6ffed193f52f001761bf8p",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Trend two: Bridging the trust gap will be a business priority",
        id: "5f6ffjd193f42f001761bf8o",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Research suggests that compared with low-trust organisations, people in high-trust organisations have a substantially better employee experience. They were found to be 76% more engaged, 74% less stressed and 50% more productive. How can you leverage this and make your organisation ‘high-trust’?",
        id: "5f6ffld193f42f001761bf8n",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Trust expert Rachel Botsman argues that the world is going through “one of the greatest trust shifts of all time – from institutional to distributed trust”. Where institutional trust was characterised by top-down hierarchies and placing our trust in centralised organisations, this new era of distributed trust sees us put our faith in decentralised networks (think social media or marketplaces like Uber). This shift is reflected in organisations and, with it, employees are expecting greater transparency and accountability from leaders.",
        id: "5f6ffed193f42f001761bf8m",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Leadership behaviours such as building social relationships, granting autonomy and sharing information broadly are all things that can contribute to a high-trust culture.",
        id: "5f6ffed193f52f001761bf8l",
        type: "paragraph"
      },
      {
        caption: "",
        body: "However, it’s also important to consider processes, systems and structure, too, which strongly influence us day-to-day, nudging us towards (or away from) the right behaviours. They also act as ‘silent communicators’ revealing what is truly valued by the organisation.",
        id: "5f6ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "If you want to inspire trust, make sure that both behaviours and systems are bridging the gap between what you say is important and what you value.",
        id: "5f6ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Trend three: Employee experience will be designed for human connection through and beyond work",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "We know that a desire for social connection is hard-wired into us and critical for our wellbeing. With the rise in digital and distributed working, and the British Red Cross reporting 10% of workers feeling lonely at work, we are facing unique challenges.",
        id: "5f7ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "We also know that having good relationships with our colleagues contributes to a more positive employee experience and that it makes us perform better too.",
        id: "5f7ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The organisation plays a powerful role in making it much easier – or harder – for people to build good relationships and feel connected to others. There is a case to be made that it’s easier to build relationships in person, and there are ways to promote more of this.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "However, to future-proof your employee experience, we believe it’s important to focus on strategies that will work regardless of whether people are physically together or not. Look at the whole employee journey and ask yourself, what are the current touchpoints and how can these become more ‘human’?",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "There are two lenses to use here. Firstly, connection through work – the social interactions you have during your day-to-day work and the meaningful relationships you can hopefully build. This comes down to regular, open conversations and opportunities to cross paths with others.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The other lens is connection beyond work. This could be through team volunteering days, social committees, cross-organisation mentoring programmes and so on.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Leveraging AI to enhance employee experience",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Paradoxically though, helping employees form stronger social relationships could simply be through less time spent working. With increasing access to generative AI, we are more productive than ever before.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Will organisations choose to fill this time with more work, or will they use it to enhance the EX? Whether that’s the four day (or 4.5-day) week, whole-company shut down days, or something else, by gifting people more time to focus on their wellbeing and deepen their relationships with friends, family and their community, the organisation will get huge rewards too.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Future of work: Designing a magnetic employee experience",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "To create a truly magnetic workplace that makes people feel a sense of meaning, trust and connection...",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "1. Put purpose at your core",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Employees are increasingly likely to prioritise purpose. To make sure your organisation is doing the same, start with an inspiring, well-written, authentic and plausible purpose statement; and work to embed it throughout the organisation.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Align your strategy, hiring and leaders behind it; support your employees to make it their own; and focus on genuine impact in your chosen area.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "2. Bridge the trust gap",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Technological advances are evolving the ways we view and build trust. Organisations can build a high-trust culture by focusing on behaviours, such as how leaders communicate and build relationships, and systems, to truly embed priorities into your processes and policies.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "3. Consciously design for human connections",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Our need for strong social connection isn’t going anywhere. By redesigning key touchpoints to foster more connection and community and by thinking holistically about the employee’s life at and beyond work, organisations can do their part to make work more human.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "By continuously reviewing these three areas, organisations can future-proof their EX, attracting AND keeping the best talent.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },

  {
    id: "65bc0f0ee4d4db001510f260",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/shutterstock_2265417157+-+William+Fletcher+_+WorkL.jpg",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title:
      "Channelling chameleons: Why HR team’s must be agents of change in 2024",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: https://hrzone.com/channelling-chameleons-why-hr-teams-must-be-agents-of-change-in-2024/",
        id: "5f1ffed193f42f081761bf45",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Written by: Alan Price, Peninsula Business Services, Employment Law & HR Director",
        id: "5f6ffed193f42f081761bf673",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Just like chameleons, HR teams must be adaptable to changing surroundings. With their role being increasingly put under the public and social media microscope, adjusting quickly to new employment laws and policies is more important now than ever.",
        id: "5f6ffed193f42f081761bf72",
        type: "paragraph"
      },
      {
        caption: "",
        body: "In 2024, HR professionals and the companies they work for are increasingly under the spotlight of social media and news sites looking for the latest employment scandal.",
        id: "5f6ffed193f42f081761bf71",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Viral TikToks with dodgy layoff conversations; “QuitTok”, a new phenomenon where employees film their exit from a company; and social media accounts that read HR emails gone wrong aloud. It can all quickly become a PR nightmare.",
        id: "5f6ffed193f42f001768bf8z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Because of this, HR teams need to not only tread carefully but become agents of fast change to make sure their policies, procedures, advice and all communications with employees are near infallible.",
        id: "5f6ffed193f42f001768bf8y",
        type: "paragraph"
      },
      {
        caption: "",
        body: "It’s a lot of pressure, especially as laws change all the time. But here’s why HR teams must be agents of change in 2024, and how mastering change is key to high-performing HR teams…",
        id: "5f6ffed193f42f001768bf8x",
        type: "paragraph"
      },
      {
        caption: "",
        body: "If you’re not changing, you’re not learning",
        id: "5f6ffed193f42f001867bf8w",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Where sales teams have an “always be closing” attitude, HR teams must adopt an “always be learning” mindset.",
        id: "5f6ffed193f42f001761bf8v",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Having up-to-date knowledge of new laws will help them also have a positive and clear internal communications strategy. This gives companies a better advantage when it comes to keeping employees happy and on-side.",
        id: "5f6ffed193f42f001761bf8u",
        type: "paragraph"
      },
      {
        caption: "",
        body: "But as HR managers are already expected to be employment law experts too, who teaches the teacher? Well, regular on and off-site training days are recommended to keep your HR team in the loop with changing laws.",
        id: "5f6ffed193f42f001761bf8t",
        type: "paragraph"
      },
      {
        caption: "",
        body: "HR teams might have third-party support of some kind, but failing that, staying in tune with weekly news and subscribing to a reliable legal newsletter can be beneficial.",
        id: "5f6ffed193f42f001761bf8s",
        type: "paragraph"
      },
      {
        caption: "",
        body: "It’s also important to check in on capacity to allow for learning. If your HR team is overwhelmed and fighting fires, ie. only dealing with emergencies or urgent matters, they won’t have as much time for growth or learning in the realm of employment law.",
        id: "5f6ffed193f42f701761bf8r",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Don’t fear change, change fear",
        id: "5f6ffed193f42f008761bf8q",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Despite the rise of artificial intelligence (AI) and technology in the industry, HR is still a relatively traditional profession. This leaves room for fear of the new and unknown.",
        id: "5f6ffed193f52f001761bf8p",
        type: "paragraph"
      },
      {
        caption: "",
        body: "But if HR teams can harness the power of tools like AI-powered recruitment, administrative automation and pattern and trend data analytics, they can transform processes and get more time for decision making and exploring growth opportunities.",
        id: "5f6ffjd193f42f001761bf8o",
        type: "paragraph"
      },
      {
        caption: "",
        body: "HR teams can unlock the full benefits of AI and use it to increase their adaptability. But they must have a good understanding of the risks and challenges involved.",
        id: "5f6ffld193f42f001761bf8n",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Accuracy, employee data protection and avoiding bias are all things that should be thoroughly researched before adopting AI. But the most important lesson is, as with any change, it’s not to be feared but harnessed.",
        id: "5f6ffed193f42f001761bf8m",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Human nature is even more unpredictable than employment law You might know your employment policies and processes inside out, but real life is messy. Therefore, HR teams need to be prepared for anything and have a solid understanding of human nature.",
        id: "5f6ffed193f52f001761bf8l",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Training HR leaders on employment law alongside soft skills like active listening and conflict resolution is key.",
        id: "5f6ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "We’re living in an era of not only surveillance, but one where honesty and authenticity is championed online. Disgruntled employees are being encouraged to tell their stories.",
        id: "5f6ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "This means HR teams need to be extra emotionally intelligent and careful in making sure the way they handle any kind of dispute is lawful and above reproach.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "News travels in a click nowadays meaning HR teams need to adapt. Any misstep can have far-reaching consequences. It’s important HR leaders foster a positive culture and uphold company values, acting with fairness, respect and inclusivity in everything they do.",
        id: "5f7ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "They must also be prepared to thoroughly investigate any accusations that do come about on review sites or social media, to make sure they demonstrate a commitment to accountability and protect the reputation of the business.",
        id: "5f7ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "As of April, employees have a new right to request flexible working. HR teams are critical to helping companies embrace the change of remote, hybrid, cross-functional teams by promoting practices that enable greater flexibility and collaboration.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "HR’s role is to guide employees through transitions to help bring more positive outcomes for both stakeholders and employers alike.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "As well as being agents of change themselves, the role of the HR professional in 2024 is to minimise the disruption of change. Thereby supporting employees with the resources they need to thrive and helping them adapt to changing circumstances.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "And just like our ever-camouflaging friend the chameleon, HR professionals must continue to adapt to keep up with the changes and challenges brought about by the modern world of work.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "65bc0f0ee4d4db001510f250",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/large+_+high+res-Online-meeting+-+William+Fletcher+_+WorkL.jpg",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title:
      "Bupa: Why we put diversity and inclusion at the heart of employee wellbeing",
    summary: "",
    content: [
      {
        caption: "",
        body: "Tom Hoosen-Webber, Chief People & Procurement Officer, Bupa Global, India & UK",
        id: "5f1ffed193f42f081761bf45",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Tom is Chief People & Procurement Officer for Bupa Global, India & UK. He joined Bupa in 2018, having previously held the role of Transformation and Change Director at TalkTalk. He has held senior HR and transformation roles across sectors, including manufacturing, supply chain and telecoms.",
        id: "5f6ffed193f42f081761bf673",
        type: "paragraph"
      },
      {
        caption: "",
        body: "https://www.linkedin.com/in/tom-webber",
        id: "5f6ffed193f42f081761bf72",
        type: "paragraph"
      },
      {
        caption: "",
        body: "www.bupa.co.uk",
        id: "5f6ffed193f42f081761bf71",
        type: "paragraph"
      },
      {
        caption: "",
        body: "At Bupa, everyone is welcome. With 23,000 colleagues across the UK, our people are at the heart of our business, providing high quality services to our patients, residents and customers every day. ",
        id: "5f6ffed193f42f001768bf8z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "We truly believe that our people are at their best when they can bring their whole selves to work, and a happy, healthy workforce is essential for business performance, productivity and talent retention.",
        id: "5f6ffed193f42f001768bf8y",
        type: "paragraph"
      },
      {
        caption: "",
        body: "And it’s essential that our workforce reflects the diversity of our customers so that we can understand and evolve to their needs. This helps us deliver our purpose; helping people live longer, healthier, happier lives and making a better world.",
        id: "5f6ffed193f42f001768bf8x",
        type: "paragraph"
      },
      {
        caption: "",
        body: "So how do we create an open culture that celebrates difference and has inclusion at its heart?",
        id: "5f6ffed193f42f001867bf8w",
        type: "paragraph"
      },
      {
        caption: "",
        body: "On a practical level it starts with celebrating that everyone is different. Our people are our drivers of inclusion, and it’s through listening that we’ve designed support and benefits to meet their needs. ",
        id: "5f6ffed193f42f001761bf8v",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Our Be You at Bupa network is designed to provide peer-to-peer support for colleagues through community groups who provide a representative voice on things such as race and ethnicity, faith, gender and health concerns.",
        id: "5f6ffed193f42f001761bf8u",
        type: "paragraph"
      },
      {
        caption: "",
        body: "We also reviewed our Viva healthcare benefits to make them inclusive, accessible and tailored– particularly for the 15,000 people working in our care homes, dental practices and clinics. We devised new medical benefits in response to colleague feedback. The support is all free and available to gift to friends and family - we know our people worry about their families’ health and we didn’t want cost to prevent colleagues using them.  Under our new Bupa Viva Benefits platform, colleagues now have easy access to mental health and physiotherapy support, GP appointments at evenings and weekends, as well as vital services such as cancer checks, menopause support and our Bupa health assessments.",
        id: "5f6ffed193f42f001761bf8t",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Most recently we launched our Bupa Period Plan, supporting our people who are struggling with symptoms, alongside our Bupa Menopause Plan launched in 2021. Our Bupa Wellbeing Index showed that almost half (47%) of women who have periods experience severe period pain most months, but just a fifth have felt comfortable to take a sick day.",
        id: "5f6ffed193f42f001761bf8s",
        type: "paragraph"
      },
      {
        caption: "",
        body: "We’ve also increased our support for colleagues who are neurodiverse, LGBTQ+, have parental responsibilities, or going through bereavement or pregnancy loss.",
        id: "5f6ffed193f42f701761bf8r",
        type: "paragraph"
      },
      {
        caption: "",
        body: "It’s vital that we understand the diverse perspectives of both our people and our customers. As the Official Healthcare Partner for ParalympicsGB, this has increased our understanding of the difficulties faced by disabled people in our society and the workplace, and what more can be done to ensure an inclusive and accessible workplace environment. ",
        id: "5f6ffed193f42f008761bf8q",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Being agile and open in our culture allows us to innovate and make tangible change, quickly. Our research showed that two in five people with less visible disabilities have avoided disclosing them at work for fear of reprisals, leading to presenteeism and poor physical and mental health. We’ve audited our physical spaces, language and culture to ensure it is accessible, increased training for our people managers on disability inclusion and workplace adjustments, and we’re committed to sharing our learnings with our corporate partners too in order to make real change throughout the landscape. ",
        id: "5f6ffed193f52f001761bf8p",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Innovation is key. We are always looking for ways to make roles easier for our people, making processes more efficient so more of their time can be spent doing what they love; providing healthcare. That’s why we have introduced digital technology into our 120+ care homes so that care notes can be updated in real time, freeing up our people to spend time with our much loved residents.",
        id: "5f6ffjd193f42f001761bf8o",
        type: "paragraph"
      },
      {
        caption: "",
        body: "We need to retain talented people as well as attracting people with transferrable skills who are looking for a new challenge. We support people with their career ambitions and provide learning, so that they can grow their careers with Bupa. ",
        id: "5f6ffld193f42f001761bf8n",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Last year we placed 1,700 apprentices across our businesses in the UK in 37 different areas including dental nursing, care services, leadership, IT and customer services. We love to see people learn and grow their careers with us and whether they want to qualify as a registered nurse, run their own care home, lead a team or even relocate across our Bupa world, we support them to achieve their goals. ",
        id: "5f6ffed193f42f001761bf8m",
        type: "paragraph"
      },
      {
        caption: "",
        body: "When it comes to supporting our people to be at their best; there is always more to be done. It’s vital that we continue to innovate and listen in order to make change. We know that to become the world’s most customer-centric healthcare company, our people must stay at the heart. ",
        id: "5f6ffed193f52f001761bf8l",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "65bc0f0ee4d4db001510f210",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/0669b249-ec9d-4050-a387-3e66975ccbf8_1712141078035 - William Fletcher _ WorkL.jpg",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "HR leaders see mental health absences rise, report shows",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: https://www.hrmagazine.co.uk/content/news/hr-leaders-see-mental-health-absences-rise-report-shows",
        id: "5f1ffed193f42f081761bf45",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Written by: Honey Wyatt",
        id: "5f6ffed193f42f081761bf673",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Nearly half (49%) of HR leaders reported seeing an increase in mental health absences, a report by mental wellbeing platform Headspace has revealed.",
        id: "5f6ffed193f42f081761bf72",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The latest edition of platform’s annual Workforce State of Mind Report also revealed that while 44% of employees turned to their managers for mental health support, only a quarter of HR leaders said that managers are required to take mental-health-specific training.",
        id: "5f6ffed193f42f081761bf71",
        type: "paragraph"
      },
      {
        caption: "",
        body: "A further 43% of employees indicated that their managers had negatively impacted their mental health by lacking an understanding of their life and work hour boundaries, or by treating team members unequally.",
        id: "5f6ffed193f42f001768bf8z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The report also highlighted that 78% of employees viewed work stress as having poorly impacted their physical health.",
        id: "5f6ffed193f42f001768bf8y",
        type: "paragraph"
      },
      {
        caption: "",
        body: "A further 76% said that work stress has caused a personal relationship to end in divorce or break up (this is most common among Gen X), while 40% indicated that work has negatively impacted their ability to care for their family or children&#39;s mental health.",
        id: "5f6ffed193f42f001768bf8x",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Pamela Gellatly, CEO of workplace healthcare provider Healthcare RM, told HR magazine that managers should consider employees' stress outside of work.",
        id: "5f6ffed193f42f001867bf8w",
        type: "paragraph"
      },
      {
        caption: "",
        body: "She explained: “Many managers feel it is not their job to consider factors that are external, but we have to as it is difficult to divide the two.",
        id: "5f6ffed193f42f001761bf8v",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“Mental health does not compartmentalise into work and non-work, it all merges together. This is made worse by home working.”",
        id: "5f6ffed193f42f001761bf8u",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Nebel Crowhurst, chief people officer at Reward Gateway, commented that managers should have specific mental health training to help employees combat stress.",
        id: "5f6ffed193f42f001761bf8t",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Speaking to HR magazine, she said: “All managers should undergo regular mental health training to equip them with the knowledge to help them identify and support various struggles that their people may be experiencing.",
        id: "5f6ffed193f42f001761bf8s",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“This will enable managers to have open conversations about how they can support their teams both in and outside of work.”",
        id: "5f6ffed193f42f701761bf8r",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Crowhurst noted that holistic benefits packages could help employees with their mental and physical health.",
        id: "5f6ffed193f42f008761bf8q",
        type: "paragraph"
      },
      {
        caption: "",
        body: "She commented: “When combating today’s stress epidemic, employers need to look at holistic support packages: rewards, subsidies, benefits and appreciation all go towards helping employees in times of crisis.",
        id: "5f6ffed193f52f001761bf8p",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“Benefits like interest-free loans on white goods could dampen some immediate financial stress. Or, why not show your support for often expensive life decisions through fertility support with family planning or valuable mortgage advice?”",
        id: "5f6ffjd193f42f001761bf8o",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Jeanette Wheeler, chief HR officer at MHR, added that employees should be able to voice their stress to people other than their managers, too.",
        id: "5f6ffld193f42f001761bf8n",
        type: "paragraph"
      },
      {
        caption: "",
        body: "She told HR magazine: “Having mental health first aiders in the workplace can provide support for work-related stress issues, as they can often provide a touchpoint for employees outside their direct team, allowing them to discuss workloads and manager-related challenges without fear of repercussions.”",
        id: "5f6ffed193f42f001761bf8m",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Wheeler continued: “Although many organisations have these services in place, they often fail to adequately signpost these support systems to employees, which can create an impression that businesses are merely paying lip service to mental health support.",
        id: "5f6ffed193f52f001761bf8l",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“As such, offerings should also be regularly discussed to remove the stigma associated with using them.”",
        id: "5f6ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "According to Headspace's report, for 40% of employees work stress contributes to serious mental health challenges, such as substance use or suicidal ideation.",
        id: "5f6ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Gellatly explained that HR could use risk assessments to understand which employees have high risk mental health, and how to support them.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "She said: “A risk assessment should be undertaken to identify the occupational hazards and who can be harmed and how. This should involve health and safety, HR, employee representatives and unions.",
        id: "5f7ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“Then – from understanding the risks either from data that is held via sickness absence management, EAP or presenteeism assessments – an organisation can build a framework from which it can build a strategy to address these issues.”",
        id: "5f7ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Headspace surveyed 1,000 CEOs, employees and HR leaders in the UK between January and February 2024.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "65bc0f0ee4d4db001910f810",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/0669b249-ec9d-4050-a387-3e66975ccbf8_1712142685033 - William Fletcher _ WorkL.jpg",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title:
      "Can technology help to offer a unified global benefits proposition?",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: https://employeebenefits.co.uk/can-technology-help-to-offer-a-unified-global-benefits-proposition/ Written by: Zoe Wickens",
        id: "5f1ffed193f42f081761bf45",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Cloud-based platforms can enable employees across the globe to access benefits through a unified proposition.",
        id: "5f6ffed193f42f081761bf673",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Availability of benefits will differ between countries.",
        id: "5f6ffed193f42f081761bf72",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Employers should ensure that their benefits technology and chosen provider are able to support varying needs in multiple countries.",
        id: "5f6ffed193f42f081761bf71",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Benefex’s Evolution report: the employee edit, published in October 2023, revealed that while 14% of organisations have a single global platform for all employee benefits provision, another 72% are planning to implement one. With more employers looking to streamline their benefits offering to employees across the globe, how can technology help to offer a unified global benefits proposition?",
        id: "5f6ffed193f42f001768bf8z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Global benefits technology",
        id: "5f6ffed193f42f001768bf8y",
        type: "paragraph"
      },
      {
        caption: "",
        body: "There are a range of technologies that global employers can access to craft a unified benefits system. These can include management information dashboards, administration systems and personalised incentives powered by artificial intelligence.",
        id: "5f6ffed193f42f001768bf8x",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Employers can also introduce platforms that offer integration with payroll systems, tailored user interfaces, and flexible and customisable benefit options.",
        id: "5f6ffed193f42f001867bf8w",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Michelle Bishop, European team lead, multinational benefits and human resources consulting at Gallagher, says: “Technology is important to enable the strategic alignment of benefits globally to business objectives and provide C-suite and business decision makers with the transparent and consolidated reporting necessary to make faster, better informed decisions.”",
        id: "5f6ffed193f42f001761bf8v",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Technology that delivers more relevant and personalised communications can help to engage workers and deliver an enhanced employee experience. Employers that want to offer interactions with providers and brokers should use effective and robust solutions.",
        id: "5f6ffed193f42f001761bf8u",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Rebekah Haymes, head of digital solutions and delivery, health and benefits at Willis Towers Watson, says: “Transaction-based technology supports digital benefit enrolment, including scenarios where flexible or voluntary benefits are in place. Live, digitally produced dashboards are important to offering information that supports the management of global benefits programmes.”",
        id: "5f6ffed193f42f001761bf8t",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Global benefits and obstacles",
        id: "5f6ffed193f42f001761bf8s",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Crafting a global benefits strategy often includes employers working with global providers to offer common benefits, such as employee assistance programmes and digital wellbeing schemes.",
        id: "5f6ffed193f42f701761bf8r",
        type: "paragraph"
      },
      {
        caption: "",
        body: "However, a challenge that global employers face is how to provide consistent benefits to their employees in different regions. They may need to tailor part of their provision to each country they operate in due to regulatory issues and varying benefits norms and cultural differences from one country to another.",
        id: "5f6ffed193f42f008761bf8q",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Employers will also need to be aware of the legislative needs of those countries to ensure compliance risks are mitigated, and local data protection regulations if personal employee data is involved.",
        id: "5f6ffed193f52f001761bf8p",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“Examples where the regulatory environment imposes constraints on an employer’s benefits might include situations regarding same-sex dependent medical cover in countries where the law forbids it,” says Haymes. “However, some local legislations encourage the provision of certain benefits through tax incentives, such as flexible welfare benefits in Italy, or wellbeing benefits in Sweden.”",
        id: "5f6ffjd193f42f001761bf8o",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Depending on the local context, availablity of some benefits will differ, and be more or less desirable. Legal and cultural differences mean that remote working is not feasible everywhere, while some countries impose caps on overtime allowances, leaving some unable to fully capitalise on additional pay opportunities.",
        id: "5f6ffld193f42f001761bf8n",
        type: "paragraph"
      },
      {
        caption: "",
        body: "National insurance contributions can vary across countries and affect employees’ net income, says Calvin Mayo, chief technology officer at Alula Technologies. Tax regulations may influence savings plans, and legal barriers may restrict stock options or equity grants, as employee ownership rules differ by country.",
        id: "5f6ffed193f42f001761bf8m",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“Employers need a platform that can handle these complexities and provide flexibility to tailor benefits to a diverse workforce,” adds Mayo. “A platform that is transparent, consistent and compliant with local laws and regulations can help achieve global benefits goals.”",
        id: "5f6ffed193f52f001761bf8l",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Offering a unified proposition",
        id: "5f6ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Harnessing technology is a good way for employers to streamline and unify benefits administration. One example is cloud-based platforms, which enable diverse and distributed employees to access and manage their benefits with ease.",
        id: "5f6ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“Benefits administration involves multiple stakeholders who may vary for each benefit and region,” says Mayo. “This creates a complex and costly challenge for employers, which may resort to generic and uniform offerings. A platform that can streamline the benefits delivery, track the usage, and optimise the costs is essential for modern employers.”",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Communication consistency will be key to global employers, even where benefits provision differs by country. Truly global technology not only supports multi-language capabilities but also local benefit types, funding methods and legislative requirements.",
        id: "5f7ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“Employers will be looking for technology that supports the in-country variances with a consistent style, ensuring that a common corporate brand and communication style is in place,” says Haymes.",
        id: "5f7ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Meanwhile, technology can also incorporate educational content, including video guides and frequently-asked questions, which can enhance the overall personalised employee experience.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“Technology also enables a greater view on the ability to roll out key initiatives and during times of crisis, such as pandemics or localised catastrophic events, allows employers to validate the protections in place to support employees’ wellbeing,” explains Bishop.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "While implementing technology that provides benefits on a global scale can present challenges, it is an achievable goal for employers. A platform that can deal with complexities and offers flexibility to a diverse workforce can ensure a global benefits proposition is unified.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "65bc0f0ee4d4db001610f910",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/0669b249-ec9d-4050-a387-3e66975ccbf8_171094618100.jpg",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "How employers can protect workers from stress at work",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: WorkL.com",
        id: "5f1ffed193f42f081761bf45",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Employers have a legal duty (in many countries) to protect their workers from stress at work. Here are some key steps employers can take to create a healthier work environment and reduce stress for their employees:",
        id: "5f6ffed193f42f081761bf673",
        type: "paragraph"
      },
      {
        caption: "",
        body: "1. Risk Assessment and Monitoring:",
        id: "5f6ffed193f42f081761bf72",
        type: "paragraph"
      },
      {
        caption: "",
        body: "-Conduct regular stress risk assessments: Identify potential stressors in the workplace, such as workload, long hours, lack of control, and poor communication.",
        id: "5f6ffed193f42f081761bf71",
        type: "paragraph"
      },
      {
        caption: "",
        body: "-Conduct regular stress risk assessments: Identify potential stressors in the workplace, such as workload, long hours, lack of control, and poor communication.",
        id: "5f6ffed193f42f001768bf8z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "-Monitor stress levels: Pay attention to absenteeism, presenteeism (being physically present but mentally disengaged), and employee feedback to gauge overall stress levels.",
        id: "5f6ffed193f42f001768bf8y",
        type: "paragraph"
      },
      {
        caption: "",
        body: "2. Addressing Workload and Work-Life Balance:",
        id: "5f6ffed193f42f001768bf8x",
        type: "paragraph"
      },
      {
        caption: "",
        body: "-Manage workload: Ensure a fair distribution of work among employees and avoid overloading individuals.",
        id: "5f6ffed193f42f001867bf8w",
        type: "paragraph"
      },
      {
        caption: "",
        body: "-Flexible working arrangements: Consider offering flexible work schedules, remote work options, or compressed workweeks to promote better work-life balance.",
        id: "5f6ffed193f42f001761bf8v",
        type: "paragraph"
      },
      {
        caption: "",
        body: "-Paid time off: Encourage employees to take breaks and use their vacation time to prevent burnout.",
        id: "5f6ffed193f42f001761bf8u",
        type: "paragraph"
      },
      {
        caption: "",
        body: "3. Promoting Communication and Support:",
        id: "5f6ffed193f42f001761bf8t",
        type: "paragraph"
      },
      {
        caption: "",
        body: "-Open communication: Foster a culture of open communication where employees feel comfortable discussing their workload, concerns, and suggestions for improvement.",
        id: "5f6ffed193f42f001761bf8s",
        type: "paragraph"
      },
      {
        caption: "",
        body: "-Employee Assistance Programs (EAPs): Provide access to confidential counselling and support services to help employees manage stress and personal challenges.",
        id: "5f6ffed193f42f701761bf8r",
        type: "paragraph"
      },
      {
        caption: "",
        body: "-Training and Resources: Offer training sessions on stress management techniques,time management skills, and healthy work habits. ",
        id: "5f6ffed193f42f008761bf8q",
        type: "paragraph"
      },
      {
        caption: "",
        body: "4. Workplace Culture and Environment:",
        id: "5f6ffed193f52f001761bf8p",
        type: "paragraph"
      },
      {
        caption: "",
        body: "-Positive work environment: Promote a positive and supportive work environment with opportunities for team building, social interaction, and recognition.",
        id: "5f6ffjd193f42f001761bf8o",
        type: "paragraph"
      },
      {
        caption: "",
        body: "-Clear expectations and deadlines: Ensure clear communication of job expectations, deadlines, and performance measures to reduce role ambiguity and anxiety.",
        id: "5f6ffld193f42f001761bf8n",
        type: "paragraph"
      },
      {
        caption: "",
        body: "-Employee recognition: Recognise and appreciate employee contributions to boost morale and create a sense of value.",
        id: "5f6ffed193f42f001761bf8m",
        type: "paragraph"
      },
      {
        caption: "",
        body: "-Ergonomics and workspace design: Ensure a comfortable and ergonomic workspace that minimises physical discomfort and promotes wellbeing.",
        id: "5f6ffed193f52f001761bf8l",
        type: "paragraph"
      },
      {
        caption: "",
        body: "5. Additional Considerations:",
        id: "5f6ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "-Addressing bullying and harassment: Implement clear policies and procedures to prevent and address bullying and harassment in the workplace, which can be significant stressors.",
        id: "5f6ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "-Leadership by example: Leaders should set a positive example by maintaining healthy work habits, taking breaks, and promoting a healthy work-life balance.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "By implementing these strategies, employers can create a work environment that protects employees from stress, promotes wellbeing, and fosters a more productive and engaged workforce.",
        id: "5f7ffed193f52f001761bf8j",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "65bc0f0ee4d4db001710f610",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/201021-Flexible-worker+-+Francesca+Dobson+_+WorkL.jpg",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "New flexible working laws: an employer's guide",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: https://www.peoplemanagement.co.uk",
        id: "5f1ffed193f42f081761bf45",
        type: "paragraph"
      },
      {
        caption: "",
        body: "With the regulations set to come into force this spring, experts advise on how businesses and HR can prepare",
        id: "5f6ffed193f42f081761bf673",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The government has announced that the new flexible working regulations will come into effect on 6 April 2024, giving employees the right to request flexible working arrangements from day one of employment. ",
        id: "5f6ffed193f42f081761bf72",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The Flexible Working (Amendment) Regulations 2023, laid before parliament on 11 December, will apply to applications made on or after 6 April 2024.",
        id: "5f6ffed193f42f081761bf71",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Under the current law, workers have to have been employed for at least 26 weeks before making a request to work flexibly. ",
        id: "5f6ffed193f42f001768bf8z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "‘Flexible working’ can refer to working patterns or hours, including part time, flexi-time, term time, compressed hours and adjusting start and finish times, as well as location; for example, working from home.",
        id: "5f6ffed193f42f001768bf8y",
        type: "paragraph"
      },
      {
        caption: "",
        body: "People Management spoke to experts about what the new regulations mean for employers and how best they can prepare.",
        id: "5f6ffed193f42f001768bf8x",
        type: "paragraph"
      },
      {
        caption: "",
        body: "What are the new regulations?",
        id: "5f6ffed193f42f001867bf8w",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The regulations are part of wider changes made in the Act, which gained royal assent in July, and which are also expected to come into force in April. ",
        id: "5f6ffed193f42f001761bf8v",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The Act will require employers to consult with the employee when they make a flexible working request before rejecting it. ",
        id: "5f6ffed193f42f001761bf8u",
        type: "paragraph"
      },
      {
        caption: "",
        body: "It will also mean the time employers have to respond to a request will be reduced to two months, from the three months they are allowed under current rules.",
        id: "5f6ffed193f42f001761bf8t",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Employees will be able to make two requests within a 12-month period, compared to the single request they are currently allowed.",
        id: "5f6ffed193f42f001761bf8s",
        type: "paragraph"
      },
      {
        caption: "",
        body: "There will also no longer be any requirement for the employee to explain what effect their request will have on the employer or how the impact might be dealt with.",
        id: "5f6ffed193f42f701761bf8r",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Review current policies ",
        id: "5f6ffed193f42f008761bf8q",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“To prepare for these changes, employers should start by reviewing their current flexible working policies and ensuring they align with the new regulations,” says Elizabeth Willetts, founder and director of Investing in Women, a flexible jobs platform.",
        id: "5f6ffed193f52f001761bf8p",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Pam Loch, solicitor and managing director of Loch Associates Group, says: “As a consequence of this change and the anticipated publicity around it, employers should anticipate a higher volume of flexible working requests.",
        id: "5f6ffjd193f42f001761bf8o",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“Employers should have in place effective processes to review and respond to applications promptly, ensuring they have trained their managers on how to deal with them too.”",
        id: "5f6ffld193f42f001761bf8n",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Upskill managers",
        id: "5f6ffed193f42f001761bf8m",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“It’s crucial to establish clear communication channels that encourage open dialogue about flexible working preferences,” explains Willetts. “Training managers to handle these requests effectively and empathically is equally important.”",
        id: "5f6ffed193f52f001761bf8l",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Loch agrees that upskilling managers is important: “With increasing variations around working hours and patterns, managers need to be skilled in delegation, enabling strong team working and fostering a high performance environment in teams that may be remote or that may not be together frequently.",
        id: "5f6ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“Investing in their development by training managers will reap benefits beyond flexible working.” ",
        id: "5f6ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Embrace a shift in workplace dynamics",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Willetts says that employers should view the changes as an opportunity to “reassess and enhance their operational models”.",
        id: "5f7ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“It’s not just about complying with the law but embracing a shift in workplace dynamics that can lead to increased employee engagement, retention and a wider talent pool,” she adds.",
        id: "5f7ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Businesses need to think about the “broader implications of flexible working for their organisational culture”, Willetts explains. “This includes investing in technology that supports remote or hybrid work, redesigning workspaces to accommodate flexible schedules and fostering a culture where output and results are valued over traditional working hours.”",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Loch adds: “The systems and ‘work flows’ that employers have in place need to be able to cope with variations on the Monday to Friday 9am-5pm working pattern.”",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "She says one example of this is considering the handover processes in place for team members who have different working patterns.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Give employees clarity",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Molly Johnson-Jones, CEO and co-founder of Flexa, tells People Management that the new regulations risk encouraging “fake flexibility”. ",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "She points out that although workers will be granted the right to request flexible working from day one, “crucially, there’s no guarantee that these requests for flexible working will be granted”. ",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“This could leave thousands of employees who rely on flexible work in precarious positions if their requests are turned down,” Johnson-Jones warns. “Individuals should know whether their needs can be accommodated before they accept a role.”",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "She suggests that employers “clearly communicate” the level of flexibility they can offer in job adverts, such as remote working or hybrid working. ",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Find a way to make requests work",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“Not all roles are going to be suitable for every requested working pattern, but approaching any requests from the point of view of ‘how could we make this work?’ is a good starting point,” says Loch. ",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "She says accommodating flexible working patterns will only benefit employers, adding: “Employees are increasingly looking for businesses to work for that value wellbeing as part of their culture, and flexible working opportunities have become a staple incentive to attract the best people.”",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Loch says businesses should consider alternatives if employees request a pattern that does not work for them. But she adds that employers should also consider genuine business requirements: “There are eight fair reasons for refusing flexible working requests and employers should think about the impact on the business of accepting or rejecting flexible working requests.”",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Acas senior adviser Simone Cheng says it will be publishing an updated statutory code of practice on requests for flexible working for employers to consult, which will be taken into account by employment tribunals: “It will include information on areas such as how to consult an employee, the need for transparency when rejecting a request and the importance of proactively offering an appeal in the event of a rejection.”",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "65bc0f0ee4d4db001710f678",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/imgarticle6.jpg",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "5 ways workplaces can support workers on the autistic spectrum",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: https://www.iriscaregroup.co.uk/blog/5-ways-workplaces-can-support-workers-on-the-autism-spectrum/",
        id: "5f1ffed193f42f081761bf45",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Written by: Dr Vaughn Price, Beechwood College’s Principal Clinical Psychologist",
        id: "5f6ffed193f42f081761bf673",
        type: "paragraph"
      },
      {
        caption: "",
        body: "An estimated one in seven people in the UK are neurodivergent, which includes people with dyslexia, dyspraxia, ADHD, autistic individuals, and other neurological functions. However, figures suggest only 22% of autistic adults in the UK are in employment.",
        id: "5f6ffed193f42f081761bf72",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Many workplaces don’t support workers on the autism spectrum, which creates overwhelming experiences for autistic individuals that begins well before starting a new job. Everything from new colleagues and work duties to a new environment, can contribute to feeling anxious and overwhelmed in the workplace.",
        id: "5f6ffed193f42f081761bf71",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Workplaces will discover many benefits of autism in the workplace when they are able to make autism-inclusive adjustments.",
        id: "5f6ffed193f42f001768bf8z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "1. Interview alternatives",
        id: "5f6ffed193f42f001768bf8y",
        type: "paragraph"
      },
      {
        caption: "",
        body: "An Interview is one of the first stages of employment in any workplace. Interviews, along with resumes, can be barriers for autistic individuals. Changes to the way interviews are carried out, whether providing options for written assessments or giving autistic candidates the questions ahead of time, are ways to make your recruitment practices more autism inclusive. If you want to assess autistic applicants, work trials are a practical and innovative way to engage a neurodiverse workforce.",
        id: "5f6ffed193f42f001768bf8x",
        type: "paragraph"
      },
      {
        caption: "",
        body: "2. Provide a mentor",
        id: "5f6ffed193f42f001867bf8w",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Providing a mentor to your autistic employees can help them learn acceptable social skills in the workplace, including how to interact with co-workers, understand different social signs and cues, and understand what unacceptable behaviour is. By providing day-to-day guidance, feedback, and reassurance, it can ensure your employees feel valued and can reduce work-related anxieties. Offering ongoing support will allow for consistent feedback, which will create reassurance and build confidence in your employees.",
        id: "5f6ffed193f42f001761bf8v",
        type: "paragraph"
      },
      {
        caption: "",
        body: "3. Create personal spaces",
        id: "5f6ffed193f42f001761bf8u",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Overstimulating environments in the workplace can lead to traumatising experiences and may discourage seeking out employment again. Creating personal spaces will provide a safe space where autistic workers can retreat to when feeling overstimulated. A few ways to reduce sensory overload in the workplace are to locate individuals in a quieter or less bright part of the office, maximise natural light, easily control temperatures, reduce strong smells, and provide sound-cancelling headphones. Sometimes this may even require changing the work location to be closer to home or other support facilities.",
        id: "5f6ffed193f42f001761bf8t",
        type: "paragraph"
      },
      {
        caption: "",
        body: "4. Alternate forms of communication",
        id: "5f6ffed193f42f001761bf8s",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Some autistic individuals have difficulty communicating in traditional ways and benefit from using alternative forms of communication. Setting clear expectations and assigning priorities of tasks will help with effective communication. Other alternate forms of communication to consider are providing advanced notice of meetings and meeting topics, accepting written responses (email or text messages) instead of verbal responses, and minimising personal conversation away from work areas. Consider using tablets to create visual schedules, step-by-step checklists, and reminders to help autistic workers complete tasks successfully and manage their time appropriately.",
        id: "5f6ffed193f42f701761bf8r",
        type: "paragraph"
      },
      {
        caption: "",
        body: "5. Training",
        id: "5f6ffed193f42f008761bf8q",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Another way a workplace can support autistic individuals is through internal training. Autism training for managers and other staff is an important step in understanding autistic individuals and recognising autistic positives and skills. Training will help to identify someone who will drive inclusion practices in your organisation and make it work long-term. As more staff are trained in working in a neuro-diverse environment, inclusion is thoughtfully incorporated into various aspects of the business, including harassment and bullying policies, recruitment processes, and environmental changes. Keep information about autism and support services readily available so it’s easily accessible for all workers.",
        id: "5f6ffed193f52f001761bf8p",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Reasonable adjustments for autism in the workplace",
        id: "5f6ffjd193f42f001761bf8o",
        type: "paragraph"
      },
      {
        caption: "",
        body: "There are many reasonable adjustments to create an autism-inclusive workplace, and they often don’t cost anything to implement. Here are some suggestions for reasonable adjustments you can make in your workplace:",
        id: "5f6ffld193f42f001761bf8n",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Create a clear routine and work schedule",
        id: "5f6ffed193f42f001761bf8m",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Flexible work hours and paid time off when needed",
        id: "5f6ffed193f52f001761bf8l",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Expectations are clear, logical, and concise",
        id: "5f6ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Use video modelling to teach skills and job tasks visually",
        id: "5f6ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Offer extra breaks when needed",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Written instructions and policies are clearly communicated",
        id: "5f7ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Provide personal digital assistants (PDAs) such as smartphones, iPads or tablets and utilize mobile apps to assist with task management and organisation",
        id: "5f7ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Benefits of autism in the workplace",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "When you’re able to meet the needs of an autistic individual and understand their strengths and skills, it creates many benefits in the workplace. Autistic individuals are often skilled and dedicated employees; they can be highly detail-oriented, productive, creative, and reliable. You can gain new perspectives by bringing in someone with a different way of thinking.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "64bc0f0ee4d4db001710f678",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/imgarticle5.jpg",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "Should leaders make exercise mandatory in the workplace?",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: https://www.hrmagazine.co.uk",
        id: "5f1ffed193f42f081761bf45",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Every Friday, staff at Swedish fashion and sportswear company Björn Borg leave their desks and head for a sweaty hour together at the local gym.",
        id: "5f6ffed193f42f081761bf673",
        type: "paragraph"
      },
      {
        caption: "",
        body: "There’s nothing unusual about that. Except that this is compulsory for every employee at the Stockholm HQ. The philosophy at the company, founded by the Swedish tennis star, is that working out together helps to develop a winning team.",
        id: "5f6ffed193f42f081761bf72",
        type: "paragraph"
      },
      {
        caption: "",
        body: "It’s a policy found at other companies in Sweden. But should forcing staff to exercise during work time be something that UK firms should consider copying?",
        id: "5f6ffed193f42f081761bf71",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Investing in the health of your staff seems like a no-brainer, but is compulsory exercise a step too far?",
        id: "5f6ffed193f42f001768bf8z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Managers should encourage exercise breaks at work, researchers say",
        id: "5f6ffed193f42f001768bf8y",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Fit for office",
        id: "5f6ffed193f42f001768bf8x",
        type: "paragraph"
      },
      {
        caption: "",
        body: "There are lots of benefits for your employees if they exercise: they’ll feel healthier, more alert and energetic. It can help to ease stress, build resilience and forge better social connections.",
        id: "5f6ffed193f42f001867bf8w",
        type: "paragraph"
      },
      {
        caption: "",
        body: "They may also enjoy a healthier bank balance, as some private health insurance companies offer discounts on premiums and other perks for more active lifestyles.",
        id: "5f6ffed193f42f001761bf8v",
        type: "paragraph"
      },
      {
        caption: "",
        body: "But why pay staff to exercise?",
        id: "5f6ffed193f42f001761bf8u",
        type: "paragraph"
      },
      {
        caption: "",
        body: "There’s plenty of evidence that getting active during work time offers unique benefits to the employer.",
        id: "5f6ffed193f42f001761bf8t",
        type: "paragraph"
      },
      {
        caption: "",
        body: "One study found that employees visiting a company gym were more productive in their jobs and went home more satisfied on days when they exercised during their work time.",
        id: "5f6ffed193f42f001761bf8s",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Why it pays for employers",
        id: "5f6ffed193f42f701761bf8r",
        type: "paragraph"
      },
      {
        caption: "",
        body: "If staff exercise during the workday, they get a sense of achievement as well as an injection of happy hormones that can last all day.",
        id: "5f6ffed193f42f008761bf8q",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Another study found that people experienced an immediate improvement in cognition following 15 minutes of moderate exercise – with the business reaping the benefits if they work out during work, rather than after.",
        id: "5f6ffed193f52f001761bf8p",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Healthy and happy employees are more likely to be motivated and creative, and less likely to take sick days. Add in supercharged productivity, social connections, loyalty, staff retention and boosted profits, and surely it’s a win-win?",
        id: "5f6ffjd193f42f001761bf8o",
        type: "paragraph"
      },
      {
        caption: "",
        body: "How to get your staff across the finish line",
        id: "5f6ffld193f42f001761bf8n",
        type: "paragraph"
      },
      {
        caption: "",
        body: "While some of your employees will yearn for the Swedish approach, there will be others who’d rather sit it out.",
        id: "5f6ffed193f42f001761bf8m",
        type: "paragraph"
      },
      {
        caption: "",
        body: "If your company introduces mandatory exercise, some staff may rebel and refuse to get involved. And if they won’t change their minds, you could find yourself in a sticky situation.",
        id: "5f6ffed193f52f001761bf8l",
        type: "paragraph"
      },
      {
        caption: "",
        body: "So, what are the alternatives if you’re not ready to make exercise compulsory?",
        id: "5f6ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "During my career as a coach and trainer with England Rugby, I helped develop some of the country's top players and coaches. If one of your goals for 2024 is healthier and happier teams, here are some alternative ways to achieve it.",
        id: "5f6ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Offer discounted or free gym memberships ",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Communicate this perk to all your current staff plus during recruitment and onboarding. Stay flexible about when staff can use the gym. And include a family membership option.",
        id: "5f7ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Or hire a personal trainer to come into the office and run a variety of sessions throughout the day to suit different needs",
        id: "5f7ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Find your zen",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Bring yoga and fitness classes into the workplace; if you make exercise fun and easy to join, there are fewer reasons for people to resist.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Humans aren’t designed to sit still for eight hours a day. It’s bad for us physically, and movement also aids creativity and thinking. You can also introduce standing desks.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Fuel their day",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Make nutritious snacks available. This doesn’t have to cost a fortune and could be anything from fresh fruit, to starting the morning with a healthy breakfast muffins delivery.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Keep on running",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Empower your teams to set up their own lunchtime runs or walks. It’s a great opportunity to get to know each other away from work – and we’re all on an equal footing when we put on our trainers.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "64bc0f0ee4d4db001710f978",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/Imgarticle4.jpg",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "WHY NO ORGANISATION CAN AFFORD TO LAUGH OFF EMPLOYEE STRESS",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: https://www.thehrdirector.com",
        id: "5f1ffed193f42f081761bf45",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Discover the staggering impact of workplace stress on employee well-being and organisational productivity. With mental health challenges on the rise, organisations must prioritise proactive measures to support their workforce.",
        id: "5f6ffed193f42f081761bf673",
        type: "paragraph"
      },
      {
        caption: "",
        body: "It’s no secret that excessive stress is a health hazard. In fact, the World Health Organisation (WHO) has classified stress as the health epidemic of the 21st century. And yet, many employees still fail to take the concept of stress seriously – or worse, laugh it off altogether.",
        id: "5f6ffed193f42f081761bf72",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Almost seven in 10 Gen Z workers (65 per cent) said senior colleagues struggled with technology, and 22 per cent of Gen Z workers said there had been a rise in workplace inequities between the generations.",
        id: "5f6ffed193f42f081761bf71",
        type: "paragraph"
      },
      {
        caption: "",
        body: "We all experience stress – it’s a normal part of personal and professional life. But the latest research suggests that cases of workplace stress are on the rise, and the human and company cost is huge.",
        id: "5f6ffed193f42f081767bf77",
        type: "paragraph"
      },
      {
        caption: "",
        body: "A recent study by Mental Health UK found one in five employees have required time off due to poor mental health cayes, Mental Health trainer and preventative services lead – Vita Health Groupused by stress in the past year, and in 2022/2023 work-related stress, depression and anxiety resulted in 35.2 million lost working days.",
        id: "5f6ffed193f42f001768bf8z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Employers are paying a high price for our increasingly stressed-out nation – the estimated yearly cost of stress and poor mental health for UK employers is between £33-42bn, with over half of that due to presenteeism.",
        id: "5f6ffed193f42f001768bf8y",
        type: "paragraph"
      },
      {
        caption: "",
        body: "It begs the question: can organisations really afford to be laughing off employee stress? And if not, what can they do to prevent stress reaching crisis point, both for their employees and themselves?",
        id: "5f6ffed193f42f001768bf8x",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Is stress really so bad?",
        id: "5f6ffed193f42f001867bf8w",
        type: "paragraph"
      },
      {
        caption: "",
        body: "We know that stress is an unavoidable part of life, but too much stress could be a recipe for disaster and the human cost, catastrophic. Left unchecked and unmanaged, stress can interfere with someone’s ability to lead a normal life for a significant period of time.",
        id: "5f6ffed193f42f001761bf8v",
        type: "paragraph"
      },
      {
        caption: "",
        body: "In the workplace, you might notice the impact of stress in an employee’s emotional wellbeing. Symptoms can include reduced work performance, social withdrawal, emotional outbursts, poor concentration, and reduced confidence.",
        id: "5f6ffed193f42f001761bf8u",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The fact is, you cannot separate mental from physical health – one will always have an effect on the other. In longer-term episodes, the activation of the stress response system can disrupt almost all of the body’s processes.",
        id: "5f6ffed193f42f001761bf8t",
        type: "paragraph"
      },
      {
        caption: "",
        body: "With all of this in mind, it’s little wonder that mental ill-health is the leading cause of sickness absence in the UK.",
        id: "5f6ffed193f42f001761bf8s",
        type: "paragraph"
      },
      {
        caption: "",
        body: "How is stigma contributing to the problem?",
        id: "5f6ffed193f42f701761bf8r",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Although we’ve come a long way, there’s still a perception among many that having mental health challenges is a sign of weakness.",
        id: "5f6ffed193f42f008761bf8q",
        type: "paragraph"
      },
      {
        caption: "",
        body: "If employees feel they cannot speak up and speak out about how they are feeling, it may only compound their feelings of stress and anxiety.",
        id: "5f6ffed193f52f001761bf8p",
        type: "paragraph"
      },
      {
        caption: "",
        body: "In fact, a Mind poll found that 95% of employees calling in sick with stress gave a different reason for being off and 48% said they would not talk to their employer about their mental health.",
        id: "5f6ffjd193f42f001761bf8o",
        type: "paragraph"
      },
      {
        caption: "",
        body: "It can be particularly hard to build an accepting culture in workplaces where employees mainly use stress as a motivator. These employees may find it harder to empathise with, and have little tolerance for, those who find stress difficult and overwhelming.",
        id: "5f6ffld193f42f001761bf8n",
        type: "paragraph"
      },
      {
        caption: "",
        body: "How to deal with stress in the workplace",
        id: "5f6ffed193f42f001761bf8m",
        type: "paragraph"
      },
      {
        caption: "",
        body: "It’s vital that organisations – for whom many are currently desperate to retain and attract employees amid current labour shortages – up their game.",
        id: "5f6ffed193f52f001761bf8l",
        type: "paragraph"
      },
      {
        caption: "",
        body: "In their review of mental health and employers in 2017, Stevenson and Farmer outlined an approach and vision which included moving to a society where ‘all of us become more aware of our own mental health, other people’s mental health and how to cope with our own and other people’s mental health when it fluctuates’.",
        id: "5f6ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "To achieve their vision, Stevenson and Farmer set out a framework of mental health core standards drawn from best practice and evidence, which are as follows:",
        id: "5f6ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "• Produce, implement and communicate a mental health at work plan;",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "• Develop mental health awareness among employees;",
        id: "5f7ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "• Encourage open conversations about mental health and the support available when employees are struggling;",
        id: "5f7ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "• Provide employees with good working conditions and ensure they have a healthy work life balance and opportunities for development;",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "• Promote effective people management through line managers and supervisors;",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "• Routinely monitor employee mental health and wellbeing.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "• It’s evident that managing workplace stress and mental health is not all about policy and process. ",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "A huge part of it is ensuring employees – especially managers as those who often have the biggest influence on an employee’s experience – are equipped with the skills and confidence to spot the signs, know the right questions to ask, and understand where to guide a person to for support. A Mental Health First Aid course would help to provide employees with a great foundation.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Above all else, workplaces must be safe spaces where employees feel they can talk about their mental health, without being punished for doing so. Too often, the fear of speaking out prevents individuals from accessing the support they may desperately need.",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The question to ask yourself now is this: do you want your organisation to be part of the problem or the solution?",
        id: "5f6ffed193f52f001761bf8i",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "63bc0f0ee4d4db001710f978",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/imgarticle3.png",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "Is there a 'generational war' playing out in workplaces?",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: https://www.peoplemanagement.co.uk",
        id: "5f1ffed193f42f081761bf75",
        type: "paragraph"
      },
      {
        caption: "",
        body: "A report has warned that a “generational war” may be playing out in offices as divides grow between younger and older workers over communication styles and attitudes to technology.",
        id: "5f6ffed193f42f081761bf673",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Half of employees (50 per cent) over 50 said they were annoyed by younger colleagues' lack of traditional tools such as pens, while just under half (47 per cent) of Gen Z employees felt that older workers slowed things down with “dated” working techniques.",
        id: "5f6ffed193f42f081761bf72",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Almost seven in 10 Gen Z workers (65 per cent) said senior colleagues struggled with technology, and 22 per cent of Gen Z workers said there had been a rise in workplace inequities between the generations.",
        id: "5f6ffed193f42f081761bf71",
        type: "paragraph"
      },
      {
        caption: "",
        body: "How reverse mentoring could help Gen Z and older colleagues understand each other",
        id: "5f6ffed193f42f081767bf77",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Half of Gen Z think ChatGPT gives better career advice than managers – People Management puts it to the test",
        id: "5f6ffed193f42f001768bf8z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "It’s time for the dinosaurs to stop moaning about Gen Z",
        id: "5f6ffed193f42f001768bf8y",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Eliza Filby, historian at Generational Evolution, told People Management: “The generational gap in today's workplace is wider than ever before. We are dealing with up to four generations working in the same environment, each with vastly different values, expectations and communication styles.”",
        id: "5f6ffed193f42f001768bf8x",
        type: "paragraph"
      },
      {
        caption: "",
        body: "She added that “this collision of generational mindsets is shaking up the traditional corporate culture in unprecedented ways”, meaning that “bridging this divide in how work itself is perceived is a massive challenge”.",
        id: "5f6ffed193f42f001867bf8w",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The Digital Etiquette: Mind the generational gap report, which surveyed 4,000 knowledge workers in the UK, US, Canada, Australia and Germany, discovered that the majority (90 per cent) of teams reported conflicts over digital tools, with three in five (60 per cent) admitting that these disagreements impeded productivity and collaboration.",
        id: "5f6ffed193f42f001761bf8v",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Of the 1,000 respondents in the UK, 11 per cent said their teams often had conflicts because of different preferred tools or platforms used, while nearly half (46 per cent) said they sometimes had tensions over differing technology use.",
        id: "5f6ffed193f42f001761bf8u",
        type: "paragraph"
      },
      {
        caption: "",
        body: "In the study 43 per cent of teams reported misinterpretations of tone or context in digital communications; 33 per cent cited mismatched response time expectations; and a further 33 per cent reported confusion over the use of emojis.",
        id: "5f6ffed193f42f001761bf8t",
        type: "paragraph"
      },
      {
        caption: "",
        body: "But the report also suggested there could be greater room for collaboration between generations, as more than half (53 per cent) of Gen Z workers said they envied older colleagues' phone confidence.",
        id: "5f6ffed193f42f001761bf8s",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Samantha Mullins, director of Latitude HR, told People Management: “Within the current work environment there are employees who remember the transition from paper to email, working alongside digital natives and everything in between.",
        id: "5f6ffed193f42f701761bf8r",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“With this, there has also been a reduction in the formality of the workplace, what might be perceived as appropriate to a younger generation can be seen as too informal to the older generation.”",
        id: "5f6ffed193f42f008761bf8q",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Mullins added: “Organisations need to acknowledge that lived experience across the generations is very different and create an environment where peers can learn from each other and collectively lean on the different strengths and perspectives across the team to get better outcomes.”",
        id: "5f6ffed193f52f001761bf8p",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Matt Stephens, founder and co-CEO of Inpulse, told People Management that “the rise and rise of generational tension continues”, adding that he was “not surprised” by the findings.",
        id: "5f6ffjd193f42f001761bf8o",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“We see the same challenges when it comes to the way in particular Gen Z related to and communicates with boomers. It’s like they are talking two different languages, combined with the cultural filter they hear each other through,” Stephens said.",
        id: "5f6ffld193f42f001761bf8n",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Millennial managers – the generation between Gen Z and boomers – can act as a “bridge” between the generations and act as “interpreters” if any miscommunications arise, he suggested.",
        id: "5f6ffed193f42f001761bf8m",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“Millennials typically are also more willing to go further to understand something, whereas Gen Z, currently, while saying they are the most open generation, seem to be less willing to explore, be curious and give the benefit of the doubt,” Stephens continued.",
        id: "5f6ffed193f52f001761bf8l",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“Helping younger employees understand both that it’s OK to disagree without falling out or leaving, and that relationships require effort and understanding rather than immediate cancelling will help,” he said, adding that it was also vital to help boomers navigate “what seems to be a moral maze because values and norms at work have changed”. ",
        id: "5f6ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "However, Carter added: “It is fair to say that the multiplicity of means of business communication today does bring its challenges, both within the workforce and in terms of client and customer interactions.",
        id: "5f6ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“Organisations should set clear standards, policies and protocols in relation to the most appropriate channels of communication within an organisation. These expectations should be communicated to the workforce, with an acknowledgement that there is no one size fits all.”",
        id: "5f1ffed193f52f001761bf8i",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "63bc0f0ee4d4db001710f978",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/Employee+Benefits+-+Antonio+Goodridge+_+WorkL.png",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title:
      "6 employee benefits that can help ease anxiety and support mental wellbeing",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: engagehealthgroup.co.uk",
        id: "5f1ffed193f42f081761bf75",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Written by: Eleanor Chivers",
        id: "5f1ffed193f42f081761bf74",
        type: "paragraph"
      },
      {
        caption: "",
        body: "One in six UK workers is affected by mental health problems like anxiety, stress and depression. Much of our lives are spent in the workplace, so it’s only right that employers take steps to ensure staff are happy and healthy.",
        id: "5f1ffed193f42f081761bf673",
        type: "paragraph"
      },
      {
        caption: "",
        body: "A healthy workplace culture consists of many factors, including good people management, recognition & rewards, and considerate workplace policies.",
        id: "5f1ffed193f42f081761bf72",
        type: "paragraph"
      },
      {
        caption: "",
        body: "And one simple thing employers can do is provide employee benefits that offer practical support for mental wellbeing.",
        id: "5f1ffed193f42f081761bf71",
        type: "paragraph"
      },
      {
        caption: "",
        body: "In this blog, we’ve listed six employee benefits that provide either preventative or reactive support to ensure everyone can benefit regardless of the current state of their mental wellbeing.",
        id: "5f1ffed193f42f081767bf77",
        type: "paragraph"
      },
      {
        caption: "",
        body: "#1 Mental Health First Aid Training",
        id: "5f1ffed193f42f001768bf8z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "A training programme to offer to employees, designed to teach staff how to understand, identify and support colleagues struggling with their mental health. The programme is a two-day course that covers a wide breadth of mental health issues, providing employees with a broad understanding of different issues and how to identify them.",
        id: "5f1ffed193f42f001768bf8y",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Benefits of Mental Health First Aid Training:",
        id: "5f1ffed193f42f001768bf8x",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Helps to reduce stigma in the workplace",
        id: "5f1ffed193f42f001867bf8w",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Promotes open discussions on mental health",
        id: "5f1ffed193f42f001761bf8v",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Opens channels of support and how to find help",
        id: "5f1ffed193f42f001761bf8u",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Provides managers with the skills to support struggling colleagues",
        id: "5f1ffed193f42f001761bf8t",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Remember, it doesn’t mean trained staff are qualified therapists! So make sure they aren’t taking on too much responsibility.",
        id: "5f1ffed193f42f001761bf8s",
        type: "paragraph"
      },
      {
        caption: "",
        body: "#2 Employee Assistance Programmes (EAPs)",
        id: "5f1ffed193f42f701761bf8r",
        type: "paragraph"
      },
      {
        caption: "",
        body: "EAPs offer staff a range of tools and services to better equip them to handle whatever life and work throws at them. Designed to strengthen the emotional resilience of staff, EAPs allow employees to feel they are taking control over their own mental health, whilst safeguarding anonymity.",
        id: "5f1ffed193f42f008761bf8q",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Not every Employee Assistance Programme is the same and each provider will offer different levels of service. But you can expect to find the following services in most EAPs:",
        id: "5f1ffed193f52f001761bf8p",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Professional counselling sessions",
        id: "5f1ffjd193f42f001761bf8o",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Managerial support",
        id: "5f1ffld193f42f001761bf8n",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Financial management, advice, and debt support",
        id: "5f1ffed193f42f001761bf8m",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Critical incident management",
        id: "5f1ffed193f52f001761bf8l",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Self-help tools, activities, and programmes",
        id: "5f1ffed193f52f001761bf8k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Benefits of Employee Assistance Programmes:",
        id: "5f1ffed193f52f001761bf8j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Helps to reduce stress and anxiety – something for every team member!",
        id: "5f1ffed193f52f001761bf8i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Employers can see where support is needed through trackable usage stats",
        id: "5f1ffed193f52f001761bf8h",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Alleviates managerial pressures",
        id: "5f1ffed193f52f001761bf8g",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Boosts workplace productivity",
        id: "5f1ffed193f52f001761bf8f",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Adaptable to teams large and small",
        id: "5f1ffed193f52f001761bf8e",
        type: "paragraph"
      },
      {
        caption: "",
        body: "#3 Group Health Cash Plans",
        id: "5f1ffed193f52f001761bf8d",
        type: "paragraph"
      },
      {
        caption: "",
        body: "A Health Cash Plan is a form of health insurance providing cover for the day-to-day health needs of staff. Typical plans will include: medical consultations, physiotherapy, dental, counselling. Business Health Cash Plans are a simpler alternative to a larger Group Health Insurance policy, keeping costs low whilst still showing staff you are prioritising their overall wellbeing.",
        id: "5f1ffed193f52f001761bf8c",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Benefits of Group Health Cash Plans:",
        id: "5f1ffed193f52f001761bf8b",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Can be easily tailored to fit with the specific needs of your business and staff",
        id: "5f1ffed193f52f001761bf8a",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Helps staff with the stresses associated with routine health issues e.g., dental and eye checks",
        id: "5f1ffed193f52f0017688885",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Easy to implement across the company",
        id: "5f1ffed193f52f0017688886",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Allows staff autonomy over everyday health issues",
        id: "5f1ffed193f52f0017688887",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Alleviates stress and worry for staff ",
        id: "5f1ffed193f52f0017688888",
        type: "paragraph"
      },
      {
        caption: "",
        body: "#4 Flexible working options",
        id: "5f1ffed193f52f0017688889",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Allowing employees to work in a way that best suits them shows you trust them to do the work they are paid for. Flexible working can relate to working hours or the location they work from.",
        id: "5f1ffed193f52f0017618881",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Benefits of flexible working options:",
        id: "5f1ffed193f52f0017618882",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Supports employees’ work-life balance",
        id: "5f1ffed193f52f0017618883",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Helps staff better handle childcare needs and elderly care responsibilities ",
        id: "5f1ffed193f52f0017618884",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Accommodates and recognises the different needs of each employee",
        id: "5f1ffed193f52f0017618885",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Helps retain employees who might look elsewhere if you don’t provide some form of flexibility",
        id: "5f1ffed193f52f0017618886",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Helps staff avoid the stress and cost of commuting to work everyday",
        id: "5f1ffed193f52f0017618887",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Saves employees time and energy",
        id: "5f1ffed193f52f0017618888",
        type: "paragraph"
      },
      {
        caption: "",
        body: "#5 Mental Health Services & Counselling",
        id: "5f1ffed193f52f0017618889",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Mental health services and one-to-one counselling can be provided through a Group Health Cash Plan, Employee Assistance Programmes, or as an added value service in a Group Health Insurance scheme. Alternatively, there are many providers in the market exclusively dedicated to mental health support and counselling resources. So mental health services can be invested in as a standalone product too!",
        id: "5f1ffed193f52f001761b881",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Benefits of providing mental health services:",
        id: "5f1ffed193f52f001761b882",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Directly supports staff mental wellbeing ",
        id: "5f1ffed193f52f001761b883",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Adaptable options to fit with budget and staff needs",
        id: "5f1ffed193f52f001761b884",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Reduces absenteeism / presenteeism",
        id: "5f1ffed193f52f001761b885",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Boosts productivity and overall morale at work ",
        id: "5f1ffed193f52f001761b886",
        type: "paragraph"
      },
      {
        caption: "",
        body: "#6 Wellness apps",
        id: "5f1ffed193f52f001761b887",
        type: "paragraph"
      },
      {
        caption: "",
        body: "A range of apps are available that provide various tools and exercises for bolstering mental wellbeing. They can consist of:",
        id: "5f1ffed193f52f001761b889",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Meditation prompts and courses ",
        id: "5f1ffed193f52f001761bf81",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- CBT exercises",
        id: "5f1ffed193f52f001761bf82",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Dietary plans and prompts",
        id: "5f1ffed193f52f001761bf83",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- Relaxing soundscapes",
        id: "5f1ffed193f52f001761bf84",
        type: "paragraph"
      },
      {
        caption: "",
        body: "- General healthy living guidance",
        id: "5f1ffed193f52f001761bf85",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Some of the market leaders include Headspace and Calm. But many insurers also offer complementary apps in their Group Health Cash Plan and Business Health Insurance plans.",
        id: "5f1ffed193f52f001761bf86",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The importance of protecting staff mental wellbeing",
        id: "5f1ffed193f52f001761bf87",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Poor mental health can be directly linked to lower job performance, reduced motivation, and, in serious cases, can affect an employee’s physical wellbeing too.",
        id: "5f1ffed193f52f001761bf88",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Supporting employees’ mental wellbeing is both the right thing and logical thing to do.",
        id: "5f1ffed193f52f001761bf89",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "63bc0f0ee4d4db001710f977",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/How+to+make+your+employees+feel+valued+in+15+different+ways+-+Antonio+Goodridge+_+WorkL.png",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "How to make your employees feel valued in 15 different ways",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: perkbox.com",
        id: "5f1ffed193f42f001761bf75",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Not everyone finds their childhood dream job right off the bat, but many people still lead fulfilled working lives. Those who find meaning and fulfilment in the eight or nine hours a day they spend with you will typically have greater job satisfaction and stick around for longer. Not only will it benefit your organisation on a financial level by reducing absence and boosting engagement, but it’ll also increase the chances of your employees living happy lives. And it will save you time dealing with issues like industrial action and employees working to rule. That’s why, if you’re interested in building a productive company culture that people want to work in, making employees feel valued is perhaps a manager’s most important job. Here are 15 ways to do it.",
        id: "5f1ffed193f42f001761bf74",
        type: "paragraph"
      },
      {
        caption: "",
        body: "1. Connect people across the company",
        id: "5f1ffed193f42f001761bf673",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Employees often feel disconnected from their colleagues – especially in times of organisational growth. This comes from a lack of understanding and appreciation of what each other does – both are needed but people don’t always know why and how. Perry Timms – Founder and Chief Executive Officer (CEO) of People and Transformational HR, offers some advice on how to make employees feel valued when they're scattered across different departments. “Talk about the real people and their stories. Share openly and without agendas. Letting people see, understand and appreciate their colleagues is a great way to build happier atmospheres. Create learning partnerships if you wish to connect team members from multiple disciplines. Let them discover what they both need to work on, and allow them to do it together despite distance.”",
        id: "5f1ffed193f42f001761bf72",
        type: "paragraph"
      },
      {
        caption: "",
        body: "2. Get creative with your perks",
        id: "5f1ffed193f42f001761bf71",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Some 66% of employees consider benefits as a good way to show appreciation for their loyalty. While dental cover and parking spaces have utilitarian value, organisations are also realising the power of creative benefits in making employees feel valued. The companies that excel in this space – Google, John Lewis, Virgin – are famed for their deep understanding of their people, something that fuels standout employee incentives, which range from bi-weekly massages to encouraging napping hours.",
        id: "5f1ffed193f42f001767bf77",
        type: "paragraph"
      },
      {
        caption: "",
        body: "3. Create opportunities for new experiences",
        id: "5f1ffed193f42f001768bf7z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Be attentive to your employees’ interests. If someone is keen to learn more about another department or area of your business, make a note to accommodate their curiosity by opening up an opportunity for them to get involved. This could mean anything from lunch with the CEO to an event that encourages them to take a position within a field they have shown interest in. Valuing employees in this way can mutually benefit your business too, as it encourages your teams to have a growth mindset and think about how they can develop within your organisation.",
        id: "5f1ffed193f42f001768bf7y",
        type: "paragraph"
      },
      {
        caption: "",
        body: "4. Modernise the workspace",
        id: "5f1ffed193f42f001768bf7x",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Physical environments have a profound impact on human behaviour and morale. This idea is neatly illustrated by the Broken Windows Theory, which links negativity within a community to sequential occurrences of negativity. Great companies don’t need extravagant offices (after all, Dyson hoovers, Hewlett-Packard computers and Instant Noodles were all invented in sheds) – the theory challenges employers to think of the wider workplace experience, to ask themselves whether their physical environment reflects how they value their team members. Does the workspace mobilise teams and facilitate company paid continuing education, for example?",
        id: "5f1ffed193f42f001867bf7w",
        type: "paragraph"
      },
      {
        caption: "",
        body: "5. Catch up with remote workers",
        id: "5f1ffed193f42f001761bf7v",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Remote working is now more prevalent than ever. The chances are that your organisation works with freelancers or long-term contractors of some kind. Whether they visit your workplace on occasion or are permanently remote, showing them appreciation will help to maintain a positive working relationship. In fact, Perry Timms says: “Who do these people download to? Share ideas with? Celebrate successful ventures with? People could partner up with their colleagues and use remote chat and video links to have regular sharing sessions. Zoom and Skype are great tools for connecting people as physically as possible. I’d also use something like monthly or quarterly get-togethers, where the discussion isn’t about business performance or strategies, but shared learning, ideas and human connection.” ",
        id: "5f1ffed193f42f001761bf7u",
        type: "paragraph"
      },
      {
        caption: "",
        body: "6. Reward team members individually",
        id: "5f1ffed193f42f001761bf7t",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Our Tackling the UK’s Disengagement Problem report found that six in 10 employees would be more influenced to remain at their current companies if they received a personalised benefits package. There will inevitably be a degree of diversity in your organisation – be it age, religion or personality – so a reward that works for one employee won’t necessarily do it for another. Benefits packages can vary hugely. Google, for example, offers its employees bi-weekly massages, while learning allowances are popular among many organisations. Whatever the programme, knowing your employees well enough to tailor rewards will have greater impact at less cost.",
        id: "5f1ffed193f42f001761bf7s",
        type: "paragraph"
      },
      {
        caption: "",
        body: "7. Relax working hours",
        id: "5f1ffed193f42f701761bf7r",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Encourage a better work-life balance and give your employees the opportunity to take a long weekend if they want to. A recent ONS study found that 50% of the UK workforce would happily lose a day’s pay if they could take a three-day weekend. It doesn’t have to be a regular occurrence – the fact it’s a one-off will make it all the more impactful. Furthermore, a four-day workweek can increase job satisfaction and make your employees feel less stressed.",
        id: "5f1ffed193f42f008761bf7q",
        type: "paragraph"
      },
      {
        caption: "",
        body: "8. Encourage employee appreciation",
        id: "5f1ffed193f52f001761bf7p",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Empowering your employees to recognise and reward each other enables authentic appreciation. As well as boosting productivity and performance, the frequent feedback and collaboration also works well to strengthen relationships and align efforts. Use a platform that makes peer-to-peer recognition available to the whole team, so everyone can share the love – the rewards could be issued for anything from successful tea runs to project milestones.",
        id: "5f1ffjd193f42f001761bf7o",
        type: "paragraph"
      },
      {
        caption: "",
        body: "9. Involve your teams in organisational goals",
        id: "5f1ffld193f42f001761bf7n",
        type: "paragraph"
      },
      {
        caption: "",
        body: "A recent study of ours found that just 41% of UK employees feel aligned with their organisations’ goals. On the surface this is disheartening, but the silver lining is that only 13% said they didn’t want to become more aligned. If a leader can connect their employees with a common goal, they lay the foundations for dedicated and loyal teams. Goal alignment also strengthens leadership and creates flexibility: with a mobilised team working towards shared objectives, businesses can execute strategy faster and with more agility.",
        id: "5f1ffed193f42f001761bf7m",
        type: "paragraph"
      },
      {
        caption: "",
        body: "10. Promote genuine transparency",
        id: "5f1ffed193f52f001761bf7l",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Regular one-to-one catch-ups only guarantee the box-ticking kind of transparency. Genuine transparency is less common, and might even at first feel counter-intuitive. If an employee asks a question about their performance, for example, answering directly might be difficult. But truthful transparency demands a degree of emotional intelligence on the manager’s part. They must neither be blunt nor shield their employee from the truth. It might cause a few uncomfortable conversations at first, but it’ll demonstrate that you value and trust them enough to tell them the truth. Promoting candid dialogue will encourage employees to repay the favour – these are the feedback loops that help businesses grow.",
        id: "5f1ffed193f52f001761bf7k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "11. Celebrate work anniversaries",
        id: "5f1ffed193f52f001761bf7j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Employees feel appreciated when their organisation takes the time to shine a spotlight on personal events, such as work anniversaries. It doesn't matter if a person has been at your firm for one year or 10, making a company-wide announcement about their anniversary will make them feel special and demonstrates you value their loyalty. When businesses think of innovative ways to make their employees feel valued on a personal level they not only attract quality talent but also increase their retention rates.",
        id: "5f1ffed193f52f001761bf7i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "12. Introduce weekly or monthly socials",
        id: "5f1ffed193f52f001761bf7h",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Employee appreciation takes many forms and organisations often forget that it's important to bring their teams together in a social environment. Hosting company events, such as summer parties, or even introducing a happy hour every Friday are great ways for your teams to put names to faces and promote tight working relationships. Taking the time to put on events where the focus is simply on socialising is an effective way to make employees feel valued, especially if your business has a lot of remote workers who don't have the opportunity to meet their colleagues often.",
        id: "5f1ffed193f52f001761bf7g",
        type: "paragraph"
      },
      {
        caption: "",
        body: "13. Financially support career goals",
        id: "5f1ffed193f52f001761bf7f",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Showing your employees you're serious about financially contributing to their professional development, demonstrates you're willing to take on the risk of investing in them and consequently value their presence in your business. Furthermore, people will find their work more meaningful when they know they're receiving the training and tools they need to further their career goals. Additionally, when business leaders prioritise career progression they can hire internal candidates who want to take on more challenging assignments in senior roles, which saves both time and money on external recruitment. Providing money for courses, or investing in tuition reimbursement schemes are all ways of showing employees you see them as human beings with their own dreams and career aspirations.",
        id: "5f1ffed193f52f001761bf7e",
        type: "paragraph"
      },
      {
        caption: "",
        body: "14. Emphasise positive feedback",
        id: "5f1ffed193f52f001761bf7d",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Frequently recognising your employees for their accomplishments results in increased job satisfaction and a healthier company culture. That said, we're all sometimes guilty of finding faults easier than praising great work, which is why employee recognition programs are so important. Lots of businesses have many ideas on how to make employees feel valued, however, sometimes the simplest gestures, such as thank you notes can brighten someone's day. Moreover, when employees receive a small gift for showing initiative, for example, it'll encourage similar behaviours across your business as it reinforces what values are important to your organisation. However, it's important to keep in mind rewards will only increase employee satisfaction when they're personal. After all, giving someone a voucher they have no intention of spending, suggests you don't know them very well and may harm their employee experience.",
        id: "5f1ffed193f52f001761bf7c",
        type: "paragraph"
      },
      {
        caption: "",
        body: "15. Make appreciation part of your internal brand",
        id: "5f1ffed193f52f001761bf7b",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Employee appreciation is a significant contributor to employee experience, and when you get it right your employees will speak highly of your business to their families and friends. Holding polls and hosting town halls are invaluable in gathering employee feedback, and you can learn a lot about what makes your people feel appreciated. For example, your teams could reveal they value gestures of public gratitude, such as employee of the month announcements, or other awards that highlight when someone has gone above and beyond in their role. Introducing regular opportunities for recognition and reward, or developing your current perks and benefits, will lay the foundations of a strong internal brand, which will help you grow a happy and healthy working environment for years to come.",
        id: "5f1ffed193f52f001761bf7a",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "63bc0f0ee4d4db001710f961",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/What+you+can+do+to+create+psychological+safety+in+the+workplace+-+Antonio+Goodridge+_+WorkL.png",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "What you can do to create psychological safety in the workplace",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: www.fastcompanyme.com/recommenders/heres-what-you-can-do-to-create-psychological-safety-in-the-workplace/",
        id: "5f1ffed193f42f001761bf61",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Written by: Suha Hasan",
        id: "5f1ffed193f42f001761bf6a",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Promoting a psychologically safe environment for employees can breed success for an organisation.",
        id: "5f1ffed193f42f001761bf6c",
        type: "paragraph"
      },
      {
        caption: "",
        body: "What does an organisation want out of its people? To do their jobs well and put forth their best efforts rather than just what's in the job description.",
        id: "5f1ffed193f42f001761bf6d",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Being innovative and helping the organisation grow is also ideal. However, research has shown that people are more likely to work in that direction if they feel good about what they do. ",
        id: "5f1ffed193f42f001761bf6e",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Psychological safety, a term coined by psychologist and psychotherapist Carl Rogers, allows for increased team performance, productivity, quality, safety, creativity, and innovation in the workplace. Social psychologists and neuroscientists say it also predicts better overall health outcomes. ",
        id: "5f1ffed193f42f001767bf6f",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“A psychologically safe environment is this idea that you can talk things through, speak up with concerns or criticisms or questions and not think that there's a bad consequence,” says Dr. Ben Hardy, Clinical Professor of Organisational Behaviour at London Business School.",
        id: "5f1ffed193f42f001768bf6g",
        type: "paragraph"
      },
      {
        caption: "",
        body: "However, it is not as easy as it seems; you must be patient and work as a team. “Underpinning is this idea that we're trying to get the right answer. 'I respect you. I value you but will push you,'” he adds. ",
        id: "5f1ffed193f42f001768bf5h",
        type: "paragraph"
      },
      {
        caption: "",
        body: "While being paid more is a good incentive for some, it isn’t always the game changer. Underscoring this, a McKinsey survey showed that an overwhelming 89% of employee respondents said they believe that psychological safety in the workplace is essential.",
        id: "5f1ffed193f42f001768bf5i",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“If morale is high, if employees feel that they are being looked after and developed, it will turn into discretionary effort,” says Dr. Hardy.",
        id: "5f1ffed193f42f001867bf6j",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Here are a few ways leaders can create a psychologically safe environment. ",
        id: "5f1ffed193f42f001761bf5k",
        type: "paragraph"
      },
      {
        caption: "",
        body: "THE OVERALL FRAME",
        id: "5f1ffed193f42f001761bf9l",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The overall frame of the conversation needs to be one where colleagues are willing to learn and understand from one another, not play the blame game.",
        id: "5f1ffed193f42f001761bf9m",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“So often, blame is actually, interestingly, not about the individual who’s done something wrong. It’s about us bigging ourselves up a bit. Instead, when there’s a problem, a better response is, ‘Okay, this has happened, what can we learn from it?’” says Dr. Hardy.",
        id: "5f1ffed193f42f001761bf9n",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Talking through the miscommunications can be vital.",
        id: "5f1ffed193f42f701761bf9o",
        type: "paragraph"
      },
      {
        caption: "",
        body: "FOLLOW THE LEADER",
        id: "5f1ffed193f42f008761bf9p",
        type: "paragraph"
      },
      {
        caption: "",
        body: "As a leader, your job is to get everyone to contribute and reel their opinions within a limit to make them feel psychologically safe.",
        id: "5f1ffed193f52f001761bf9q",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“You need to keep a bit of a handle on the extroverts and the powerful to dampen them down. Amplifying the shy, holding back the noise. This is important, particularly when you’ve got quite a lot of hierarchy,” says Dr. Hardy.",
        id: "5f1ffjd193f42f001761bf9r",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Mckinsey’s findings outline that investing in leadership development at all levels of an organisation cultivates the type of leadership behaviours that enhance psychological safety. “Employees who report that their organisations invest substantially in leadership development are 64% more likely to rate senior leaders as more inclusive,” said the report.",
        id: "5f1ffld193f42f001761bf9s",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Similarly, meetings that reach 5 or more people tend to get messy, and only a handful do most of the talking. In this scenario, a leader’s job is to try and keep the team smaller or split into subgroups. Then, the role of chairing is to get the different opinions in, not just listen to the same two people.",
        id: "5f1ffed193f42f001761bf9t",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“Another aspect of a leader is validating other people’s comments, even if you don’t agree with them. So don’t go, ‘What are you talking about?’, say ‘Okay, I’d not thought of it like that. I need to think about that a bit more,’” says Dr. Hardy. ",
        id: "5f1ffed193f52f001761bf9u",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Practising this will signify to others that you want this type of contribution. At the end of the day, enabling employee success paves the way for the organisation’s success.",
        id: "5f1ffed193f52f001761bf9v",
        type: "paragraph"
      },
      {
        caption: "",
        body: "IT’S PERSONAL",
        id: "5f1ffed193f52f001761bf9w",
        type: "paragraph"
      },
      {
        caption: "",
        body: "On a more personal level, Dr. Hardy suggests that you don’t jump the gun and instead say the second thing that comes into your head.",
        id: "5f1ffed193f52f001761bf9x",
        type: "paragraph"
      },
      {
        caption: "",
        body: "“There’s a whole idea called dual process theory, and the argument is that there are two thinking systems, one of which is fast, reflexive, while the other is slower and more deliberative. You have to grind through the gears and do the calculation,” says Dr. Hardy.",
        id: "5f1ffed193f52f001761bf9y",
        type: "paragraph"
      },
      {
        caption: "",
        body: "When you’re in a meeting, and someone tells you that your idea is terrible, the first and natural response is, “I am being attacked,” whereas a more useful response would be, “I wonder why s/he doesn’t think it’s a good idea.”",
        id: "5f1ffed193f52f001761bf9z",
        type: "paragraph"
      },
      {
        caption: "",
        body: "One more thing you can do is acknowledge your own fallibility. The key is to realise you don’t know everything and be open to criticism. ",
        id: "5f1ffed193f52f001761bf63",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "63bc0f0ee4d4db001710f960",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/neuroimag.jpg",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "Neurodiversity: 5 ways to support a neurodiverse workplace",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: www.aviva.co.uk",
        id: "5f1ffed193f42f001761bf51",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Debbie Bullock, Aviva’s Head of DEI and Wellbeing Lead, on how to create an inclusive neurodiverse wellbeing strategy.",
        id: "5f1ffed193f42f001761bf6f",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Every person is unique. We all have different traits and ways of doing things. A goalkeeper doesn’t need the pace of a winger, but they do need swift reactions and agility. A tall, powerful centre half wouldn’t need the ball skills of a top-class striker to do their job well – they bring their own strengths to the party. Each player is an asset to the team, and it’s their differences that make them more than the sum of their parts. But some people have their own unique challenges and opportunities because their brains work differently to other people. This is known as neurodivergence.",
        id: "5f1ffed193f42f001761bf6b",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Neurodiversity describes people who experience the world differently to others in social, education and workplace environments. It can include people with autism, dyslexia, ADHD and dyspraxia, amongst other conditions. Why neurodivergent employees are important to your business?",
        id: "5f1ffed193f42f001761bf6a",
        type: "paragraph"
      },
      {
        caption: "",
        body: "With around one in seven people estimated to be neurodivergent [1], it’s more than likely that you will have employees with a neurodiverse condition. Because neurodivergent think differently to neurotypical people, they can be invaluable to a business as they may approach situations differently. Often, their capacity for thinking in an unorthodox way means neurodivergent employees can be skilled at things as varied as thinking creatively, solving complex problems,identifying patterns and trends, or paying attention to detail. This difference in approach could help you find an edge in business. By bringing together different cultures, backgrounds and personalities to share their thoughts, this can reflect the customers and communities that a business operates in.",
        id: "5f1ffed193f42f001761bf69",
        type: "paragraph"
      },
      {
        caption: "",
        body: "This difference in approach could help you find an edge in business. By bringing together different cultures, backgrounds and personalities to share their thoughts, this can reflect the customers and communities that a business operates in.",
        id: "5f1ffed193f42f001767bf68",
        type: "paragraph"
      },
      {
        caption: "",
        body: "How to support neurodiverse employees",
        id: "5f1ffed193f42f001768bf67",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Protecting the wellbeing of an employee is crucial if they’re to remain engaged and happy in their work. It’s important to remember that every person has different needs, and each neurodiverse employee will have their own struggles and challenges. Ask people with lived experiences to shape help how you support your employees and consider establishing communities or getting external support. Here are some specific strategies and practices you could consider to support your employees:",
        id: "5f1ffed193f42f001768bf56",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Different people need different things from their workplace environment",
        id: "5f1ffed193f42f001768bf55",
        type: "paragraph"
      },
      {
        caption: "",
        body: "It’s easy to forget the physical environment when considering how to support neurodivergent people, but it can be an important factor. Employees may be sensitive to noise and light, so setting aside quieter, less brightly lit areas is something to consider. Desk assessments can help identify whether computer screens are the right brightness level, and whether employees have the right equipment – anything from trays and drawers to daily planners and screen overlays. Providing some opportunities to work flexibly which works for both the individual and the business can be particularly valuable in the case of neurodivergent people.",
        id: "5f1ffed193f42f001867bf68",
        type: "paragraph"
      },
      {
        caption: "",
        body: "It's well worth documenting any agreed changes – Aviva does using a Workplace Adjustment Passport, designed to make it easier for individuals and managers to understand specific needs and how to meet them. Our Workplace Adjustment Passport moves with the employee if they change role, so that they don’t have to explain the same information to a new manager or team.",
        id: "5f1ffed193f42f001761bf54",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Make your communications clear and unambiguous",
        id: "5f1ffed193f42f001761bf93",
        type: "paragraph"
      },
      {
        caption: "",
        body: "No matter what form your communications take, it’s important to say exactly what you mean – some neurodiverse people may not pick up on nuances in the same way a neurotypical person might do. Varying the format of your communications can also help people who digest information differently.",
        id: "5f1ffed193f42f001761bf92",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Help all your employees to understand neurodiversity",
        id: "5f1ffed193f42f001761bf93",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Offering training in neurodiversity can help clear up potential misconceptions, making it clear that neurodiversity isn’t an illness or a single condition. This includes promoting the use of positive language – so employees never refer to neurodiverse colleagues as ‘suffering’ from something or having learning difficulties. It’s also important to encourage an open, inclusive culture and to train managers to assign work tasks appropriately, meeting needs and playing to strengths.",
        id: "5f1ffed193f42f701761bf93",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Take a long hard look at recruitment processes",
        id: "5f1ffed193f42f008761bf93",
        type: "paragraph"
      },
      {
        caption: "",
        body: "A broad brush approach to recruitment could lead employers to miss out on the opportunity to recruit talented individuals. Not everyone is an all-rounder. For instance, a candidate may not perform brilliantly across areas like communication, being persuasive or in situations requiring a high degree of emotional intelligence – but other strengths may more than make up for this.Equally, some neurodivergent individuals may not ‘interview well’ if they avoid eye contact or stray away from the question being asked. A more flexible recruitment process – perhaps involving time spent at the potential workplace rather than a formal interview – may be a better way to understand what a candidate can do.",
        id: "5f1ffed193f52f001761bf93",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Create a workplace policy on neurodiversity",
        id: "5f1ffjd193f42f001761bf93",
        type: "paragraph"
      },
      {
        caption: "",
        body: "It may help to draw up a framework for managers and employees to follow, setting out steps to help prevent discrimination, harassment, and victimisation. This could also be a good place to signpost useful resources or support networks.",
        id: "5f1ffld193f42f001761bf93",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Building a winning team is all about coaching the individuals within it in ways that bring out their own particular strengths rather than expecting them all to be good at the same things. Neurodiversity – like other forms of diversity – can bring a business the fresh perspectives that give it a competitive edge. Let’s make sure we support it.",
        id: "5f1ffed193f42f001761bf93",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "63bc0f0ee4d4db001710f959",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/materials/images/0669b249-ec9d-4050-a387-3e66975ccbf8_1700218768204+-+William+Fletcher+_+WorkL.jpg",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "How to utilise incentives to drive employee survey response rates",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: WorkL.com",
        id: "5f1ffed193f42f001761bf50",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Utilising incentives to drive employee survey response rates can be a valuablestrategy for gathering comprehensive feedback and enhancing employee engagement. Incentives can motivate employees to participate in surveys, providing valuable insights into their perceptions, concerns, and suggestions for improvement. Here are some effective ways to utilise incentives to boost survey response rates:",
        id: "5f1ffed193f42f001761bf5f",
        type: "paragraph"
      },
      {
        caption: "",
        body: "1. Offer randomised incentives: Instead of providing a guaranteed incentive to all participants, consider implementing a randomised incentive system. This involves randomly selecting a portion of respondents to receive an incentive, increasing the perception of value and excitement.",
        id: "5f1ffed193f42f001761bf5b",
        type: "paragraph"
      },
      {
        caption: "",
        body: "2. Vary incentive types: Offer a variety of incentives to cater to different preferences and motivations. Consider offering a range of incentives, such as gift cards, raffles, and prizes.",
        id: "5f1ffed193f42f001761bf5a",
        type: "paragraph"
      },
      {
        caption: "",
        body: "3. Personalise incentives: Tailor incentives to match employee interests and preferences. Consider gathering data on employee preferences or conducting surveys to identify the most appealing incentives.",
        id: "5f1ffed193f42f001761bf59",
        type: "paragraph"
      },
      {
        caption: "",
        body: "4. Promote incentives effectively: Clearly communicate the incentive program to all employees. Use multiple channels, such as email, intranet announcements, and company newsletters, to ensure widespread awareness.",
        id: "5f1ffed193f42f001761bf58",
        type: "paragraph"
      },
      {
        caption: "",
        body: "5. Make incentives visible: Display reminders of the incentive program in prominent locations, such as break rooms, bulletin boards, or employee portals. This serves as a constant reminder and encourages participation.",
        id: "5f1ffed193f42f001761bf57",
        type: "paragraph"
      },
      {
        caption: "",
        body: "6. Deliver incentives promptly: Ensure that incentives are delivered promptly after survey completion. This reinforces the connection between participation and reward, encouraging continued engagement.",
        id: "5f1ffed193f42f001761bf56",
        type: "paragraph"
      },
      {
        caption: "",
        body: "7. Express gratitude: Thank employees for their participation in the survey, regardless of whether they receive an incentive. This fosters a culture of appreciation and encourages continued participation in future surveys.",
        id: "5f1ffed193f42f001761bf55",
        type: "paragraph"
      },
      {
        caption: "",
        body: "8. Monitor incentive effectiveness: Track the impact of incentives on survey response rates and evaluate the effectiveness of different incentive types. This data-driven approach allows for continuous improvement of the incentive program.",
        id: "5f1ffed193f42f001761bf68",
        type: "paragraph"
      },
      {
        caption: "",
        body: "9. Balance incentives with survey goals: Ensure that incentives are not the sole motivator for participation. The primary goal should be to gather genuine feedback and address employee concerns.",
        id: "5f1ffed193f42f001761bf54",
        type: "paragraph"
      },
      {
        caption: "",
        body: "10. Combine incentives with other strategies: Utilise incentives in conjunction with other strategies to promote survey participation, such as clear communication about survey importance, providing feedback summaries, and demonstrating action based on employee feedback.",
        id: "5f1ffed193f42f001761bf53",
        type: "paragraph"
      },
      {
        caption: "",
        body: "By implementing these strategies, organisations can effectively utilise incentives to drive employee survey response rates, gather valuable insights, and enhance employee engagement.",
        id: "5f1ffed193f42f001761bf52",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "63bc0f0ee4d4db001710f968",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/materials/images/shutterstock_314848616+-+William+Fletcher+_+WorkL.jpg",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "We need to better support and retain employees with endometriosis",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: https://www.hrmagazine.co.uk/content/comment/we-need-to-better-support-and-retain-employees-with-endometriosis",
        id: "5f1ffed193f42f001761bf40",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Written by: Dearbhail Ormond, Founder and CEO of frendo",
        id: "5f1ffed193f42f001761bf4f",
        type: "paragraph"
      },
      {
        caption: "",
        body: "March is Endometriosis Awareness Month, a time for us to shine a light on the disease that affects 1.5 million women in the UK.",
        id: "5f1ffed193f42f001761bf4b",
        type: "paragraph"
      },
      {
        caption: "",
        body: "It is a prevalent yet often misunderstood condition. Endometriosis is a whole-body, chronic inflammatory disease with symptoms of chronic pain, extreme fatigue and infertility. If left untreated, it can cause organ dysfunction, nerve damage and bowel obstruction. It's a debilitating condition that not only impacts personal lives but also has profound implications for professional development and career advancement.",
        id: "5f1ffed193f42f001761bf4a",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Although new standards were introduced for menstrual health and menopause in the workplace last year, conditions like endometriosis continue to be overlooked. Research we commissioned found that one in five women feel their workplaces are unsupportive of chronic health conditions, which is an alarming insight.",
        id: "5f1ffed193f42f001761bf49",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Due to its unpredictable nature, endometriosis sufferers will often look fine, but suddenly, all of that can rapidly change due to the onset of very painful and distressing symptoms. This dynamic fluctuation in wellness and the ability to perform can be difficult for employers and managers to understand. Presently, they are also not well equipped to support this.",
        id: "5f1ffed193f42f001761bf48",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Research conducted in the UK in consultation with 2,000 women found that 63% of endometriosis sufferers were told they were lying about their symptoms, affecting people's mental health greatly. More than half (51%) claimed it to have negatively affected their careers. Without the right support in place, the condition has a significant impact on productivity, which not only impacts the employee but can also impact an organisation's bottom line.",
        id: "5f1ffed193f42f001761bf47",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Thanks to increased awareness of endometriosis over the last couple of years, we are finally at an inflection point where organisations are asking what they can do to support those who suffer from the many impairments of the disease. Some corporate health insurers cover laparoscopic surgery (the gold standard diagnosis tool for endometriosis) as part of their employee policy. However, endometriosis is an incurable disease that requires long-term multidisciplinary support that must continue beyond an individual surgery.",
        id: "5f1ffed193f42f001761bf46",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The majority of businesses do not have the right tools or expertise in-house to support health conditions like endometriosis, so are looking towards digital solutions that they can incorporate into the workplace.",
        id: "5f1ffed193f42f001761bf45",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Our frendo@work app aims to bridge this gap by providing organisations with the resources they need to support their employees with endometriosis, as well as the wider organisation, including company workshops, self-management tools and scientifically-backed resources and guidance for line managers, to help them better support and communicate with staff members with the condition.",
        id: "5f1ffed193f42f001761bf44",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Endometriosis is a life-changing disease, and its far-reaching symptoms can lead to work-related issues because of a long-standing stigma and a deep misunderstanding of the condition, which creates a barrier for employees, preventing them from openly discussing their health struggles with colleagues and managers.",
        id: "5f1ffed193f42f001761bf43",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Creating an open, inclusive and engaged work environment is the antidote to a history of stigma and silence. To do this, organisations need to raise awareness amongst staff of all genders to help widen the net of education and support for those who have previously felt isolated.",
        id: "5f1ffed193f42f001761bf42",
        type: "paragraph"
      },
      {
        caption: "",
        body: "It’s also vital that employers equip managers with the right information and resources so that they are able to help employees perform at their best.",
        id: "5f1ffed193f42f001761bf41",
        type: "paragraph"
      },
      {
        caption: "",
        body: "All businesses should consider implementing an endometriosis policy as part of a company-wide positive culture shift that also includes the support mechanisms outlined above. By providing access to appropriate healthcare resources and promoting awareness and understanding among colleagues, employers can significantly improve the wellbeing and productivity of their employees.",
        id: "5f1ffed193f42f001761bf40",
        type: "paragraph"
      },
      {
        caption: "",
        body: "In addition, supporting employees with endometriosis aligns with broader goals of diversity, equity and inclusion, and is crucial for fostering an inclusive and compassionate workplace environment. It demonstrates a commitment to valuing the diverse experiences and health challenges of all employees, thereby enhancing overall morale and engagement in the workplace.",
        id: "5f1ffed193f42f001761bf4f",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Furthermore, addressing the health needs of employees not only benefits an organisation's staff but also contributes to its bottom line. By reducing absenteeism and turnover rates, businesses can reduce the costs associated with lost productivity and recruitment. Additionally, fostering an environment of empathy and support can strengthen employee loyalty and attract top talent.",
        id: "5f1ffed193f42f001761bf4e",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Ultimately, businesses have a responsibility to prioritise the wellbeing of their employees by implementing supportive policies, providing resources, and fostering a culture of understanding and inclusivity. By doing so, they not only fulfil their ethical obligations but also nurture a more resilient and thriving workforce.",
        id: "5f1ffed193f42f001761bf4d",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "63bc0f0ee4d4db001710f967",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/materials/images/db44aaf3-373a-4cd5-af1a-76a76da6925a_1673269006287.jpeg",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "Why preparation is key to workplace resilience",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: www.peoplemanagement.co.uk",
        id: "5f1ffed193f42f001761bf20",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Written by: Cyndi Sax          ",
        id: "5f1ffed193f42f001761bf1f",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Conversations are taking place in boardrooms across the world about the challenges of dealing with change. It feels as if we’ve only just weathered the last storm when another shows up unexpectedly. It is predicted that over the next decade we will move through further cycles of survival, adaptation and recovery from the impacts of global events. And research shows this is already resulting in heightened levels of stress and burnout.",
        id: "5f1ffed193f42f001761bf1e",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Unsurprisingly, resilience has become an important topic as individuals and organisations are being asked to repeatedly pick themselves up, dust themselves off and start all over again. Resilience – the capacity to adapt positively to pressure, setbacks, challenges and change – is a critical component to optimise performance and maintain wellbeing. It acts as a buffer against physical and emotional exhaustion, which affects our personal and occupational outcomes.",
        id: "5f1ffed193f42f001761bf1d",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The need for HR and L&D teams to equip employees with the resilience and skills needed for success is increasingly important. But this is made challenging by remote or hybrid environments, where it can be difficult to monitor employee engagement and burnout. We must consider ways to equip people with the tools to manage personal wellbeing and the skills to navigate each storm as it comes.",
        id: "5f1ffed193f42f001761bf1c",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Link the need to business objectives",
        id: "5f1ffed193f42f001761bf1b",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Analyse and articulate how developing resilience in the workforce will help meet critical business objectives, regardless of whatever external challenges are presented. Employees who are better prepared to manage and bounce back from stress will be more engaged and productive.",
        id: "5f1ffed193f42f001761bf1a",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Recognise that resilience can be developed",
        id: "5f1ffed193f42f001761bf19",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Greater agility is required to navigate the rapid pace of change. To remain agile, new ways of developing psychological resources should be learned, as well as strategies to deploy when dealing with challenging situations. For example, reframing and reflecting on experiences can help optimise our ability to survive negative experiences and use them to learn and grow.",
        id: "5f1ffed193f42f001761bf18",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Take a personalised approach",
        id: "5f1ffed193f42f001761bf17",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Once programme objectives are aligned to the business strategy, take a personalised approach to designing learning programmes. Consider both team and individual learning needs.",
        id: "5f1ffed193f42f001761bf16",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Blend a variety of learning modalities ",
        id: "5f1ffed193f42f001761bf15",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Accommodate and engage learners that work from remote locations, from the office, or a combination of the two by using a variety of learning modalities: digital learning, individual or team coaching, collaborative active learning experiences and others. For example:",
        id: "5f1ffed193f42f001761bf14",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Asynchronous, digital learning may be best suited to technical or theoretical content.",
        id: "5f1ffed193f42f001761bf13",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Virtual classroom environments, using videoconferencing, provide learners with the opportunity to ask questions of the facilitator, engage in discussions that promote peer learning and encourage reflection.",
        id: "5f1ffed193f42f001761bf12",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Individual coaching and/or mentoring helps participants access the support network that many crave in a hybrid work environment. It helps create space to explore the application of new skills in the workplace, seek feedback and share experiences.",
        id: "5f1ffed193f42f001761bf11",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Add validated workplace assessments",
        id: "5f1ffed193f42f001761bf10",
        type: "paragraph"
      },
      {
        caption: "",
        body: "To support employee self awareness, introduce validated assessments that measure and report on their natural strengths and development opportunities. What is their baseline level of resilience? In what areas would positive adaptations help to develop resilience?",
        id: "5f1ffed193f42f001761bf1f",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The learning and development opportunities we offer people now are the key to future organisational resilience, regardless of which storm is brewing. By equipping employees and leaders with resilience, a critical component of success today and in the years ahead, HR and L&D teams will play a crucial role in the success of their organisations, while at the same time enhancing the wellbeing and individual success of employees.",
        id: "5f1ffed193f42f001761bf1e",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Cyndi Sax is vice president of development solutions at Talogy        ",
        id: "5f1ffed193f42f001761bf1d",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  },
  {
    id: "644922dfb8f1910019170fd7",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/materials/images/0669b249-ec9d-4050-a387-3e66975ccbf8_1682514654974.jpg",
      caption: ""
    },
    categories: [
      "5dd6764d0fe419569e0788f6",
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f4"
    ],
    promote: false,
    type: "article",
    title: "Workplace mental health: 8 ways to create a positive environment",
    summary: "",
    content: [
      {
        caption: "",
        body: "Source: www.oxfordcbt.co.uk",
        id: "5f1ffed193f42f001761bf20",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Many business owners or employers feel that an employee’s mental health isn’t their issue. But the truth is that how an employee feels, thinks and acts impacts everything from their productivity to communication to their ability to maintain safety in the workplace. Helping employees improve their mental health could be one of the most important steps an employer can take to improve an individual’s well-being, as well as the health of their entire business. So how can you create a positive environment for mental health in the workplace?",
        id: "5f1ffed193f42f001761bf1f",
        type: "paragraph"
      },
      {
        caption: "",
        body: "The Cost Of Mental Health",
        id: "5f1ffed193f42f001761bf1e",
        type: "paragraph"
      },
      {
        caption: "",
        body: "In the UK, 1 in 4 people will experience a mental health problem of some kind each year. In a week, 1 in 6 people report having a common mental health problem such as anxiety or depression. With these figures so high, it is so important that business owners take action to make their workplace environment mentally healthy.",
        id: "5f1ffed193f42f001761bf1d",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Mental ill-health is the single largest cause of disability in the UK. The wider economic costs of mental illness in England have been estimated at £105.2 billion each year. This includes direct costs of services, lost productivity at work and reduced quality of living. Mental ill-health is responsible for 72 million working days lost annually. Every year it costs business £1,300 per employee whose mental health needs are unsupported",
        id: "5f1ffed193f42f001761bf1c",
        type: "paragraph"
      },
      {
        caption: "",
        body: "A healthy workplace environment will not prevent or reduce all mental health problems. These will still come from life experience and past trauma all play a role in developing mental health problems. But employers can have steps in place to help employees build mental strength so that they can stay as healthy as possible. Below are our suggestions on how to have this happen in your business.",
        id: "5f1ffed193f42f001761bf1b",
        type: "paragraph"
      },
      {
        caption: "",
        body: "8 Ways To Create A Healthy Mental Workplace Environment        ",
        id: "5f1ffed193f42f001761bf1a",
        type: "paragraph"
      },
      {
        caption: "",
        body: "1 Reduce the Stigma",
        id: "5f1ffed193f42f001761bf19",
        type: "paragraph"
      },
      {
        caption: "",
        body: "For a long time, talking about mental health was a taboo subject, and it still can be for some people. Talk about stress management, self-care and mental health in emails, meetings etc. If your employees trust that you won’t think they are “crazy” then they are more likely to speak up and receive treatment.",
        id: "5f1ffed193f42f001761bf18",
        type: "paragraph"
      },
      {
        caption: "",
        body: "2 Discuss Mental Health in the Workplace",
        id: "5f1ffed193f42f001761bf17",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Openly discuss and bring up issues related to workplace mental health such as stress, depression and anxiety and make it clear that everyone struggles to stay mentally healthy sometimes. Educate your managers in spotting the signs of mental health problems and encourage them to talk to their team. Sometimes a caring conversation can be encouraging for the employee to get help.",
        id: "5f1ffed193f42f001761bf16",
        type: "paragraph"
      },
      {
        caption: "",
        body: "3 Run a Mental Health First Aid Course",
        id: "5f1ffed193f42f001761bf15",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Mental Health First Aid teaches managers and staff what to look for, do and say so you can do the right thing at the right time. Mental Health First Aid teaches people to identify and support colleagues at work who may be experiencing or at risk of a mental health crisis and connect them with the correct employee resources for help. We at Oxford CBT offer a Mental Health First Aid course. Find out more here.",
        id: "5f1ffed193f42f001761bf14",
        type: "paragraph"
      },
      {
        caption: "",
        body: "4 Promote a Healthy Work/Life Balance",
        id: "5f1ffed193f42f001761bf13",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Without a healthy work/life balance, productivity is likely to decrease and employees will eventually burn out. Insist employees take regular breaks when they can and understand that not everyone will respond to their emails outside of work hours. Encourage your employees to develop a rich and full life outside of work and to spend time with loved ones as this will better them.",
        id: "5f1ffed193f42f001761bf12",
        type: "paragraph"
      },
      {
        caption: "",
        body: "5 Make Wellness a Priority",
        id: "5f1ffed193f42f001761bf11",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Make it a priority to help employees develop good workplace mental health habits. You could offer incentives to employees who participate in wellness programs or possibly offer free gym memberships. Exercise, healthy eating, and participation in leisure activities are a few simple ways to build mental strength and improve mental health. Some organisations have wellness days where they organise small treats for their staff, such as massages or nail treatments during the workday.",
        id: "5f1ffed193f42f001761bf10",
        type: "paragraph"
      },
      {
        caption: "",
        body: "6 Support Employees’ Efforts to Get Help",
        id: "5f1ffed193f42f001761bf1f",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Lots of employees don’t hesitate to ask for time off work for a dentist appointment for example, but many are shy when it comes to discussing and addressing their mental health needs. Make it clear that you support your employee’s efforts to take care of their minds just as much as their bodies. Allow a flexible work schedule to allow for employees to attend counselling appointments, or offer staff a mental health day when it is needed.",
        id: "5f1ffed193f42f001761bf1e",
        type: "paragraph"
      },
      {
        caption: "",
        body: "7 Encourage Mentoring and Peer Support",
        id: "5f1ffed193f42f001761bf1d",
        type: "paragraph"
      },
      {
        caption: "",
        body: "Sometimes people find it easier to speak to people who are not their managers. Peer supporters would allow staff to support one another outside of the line management structure. This would allow someone the safe space to discuss any issues they are feeling about their mental health.",
        id: "5f1ffed193f42f001761bf1c",
        type: "paragraph"
      },
      {
        caption: "",
        body: "8 Raise Awareness of Mental Health Issues",
        id: "5f1ffed193f42f001761bf1b",
        type: "paragraph"
      },
      {
        caption: "",
        body: "There are national awareness campaigns that your company could use to promote awareness of mental health issues and hold seminars or talks about mental health during these times. Mental Health Awareness Week is in May and World Mental Health Day is in October. During these times you could invite a speaker in to talk about mental health and encourage sharing of stories from people within your business.",
        id: "5f1ffed193f42f001761bf1a",
        type: "paragraph"
      }
    ],
    seriesId: "5d385f4f0f65780010d81d70",
    access: {
      price: 0,
      purchase: [],
      id: "5f1ffed193f42f001761bf21",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-07-28T10:32:49.210Z",
    updatedAt: "2020-07-28T10:32:49.216Z",
    createdAt: "2020-07-28T10:32:49.216Z"
  }
]

const lectures = [
  {
    id: "5f4ed98e2ab8af001861a902",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/materials/images/5d2f0095e8368b00282bfc5d_1599002999966.jpeg",
      caption: ""
    },
    categories: ["5d149ad7dcb3ad8a5440ed0f", "5d149ad7dcb3ad8a5440ecda"],
    promote: false,
    type: "lecture",
    title: "How to get serious about diversity and inclusion in the workplace",
    summary:
      "In this candid talk, inclusion advocate Janet Stovall shares a three-part action plan for creating workplaces where people feel safe and expected to be their unassimilated, authentic selves.\n\nSource: ted.com",
    content: [
      {
        caption: "",
        body: "",
        id: "5f4ed98e2ab8af001861a903",
        type: "video",
        source:
          "https://cdn.api.video/vod/vi2XidINnWH8tH8JTjLTfOhQ/hls/manifest.m3u8"
      }
    ],
    seriesId: null,
    access: {
      price: 0,
      purchase: [],
      id: "5f4ed98e2ab8af001861a904",
      type: "public"
    },
    userId: "5d2f0095e8368b00282bfc5d",
    user: {
      firstName: "WorkL",
      lastName: "Business Curator",
      profile: {
        jobTitle: "Content Curator",
        picture: {
          small:
            "https://media.engaging.works/production/media/profile/small_db44aaf3-373a-4cd5-af1a-76a76da6925a_1622634407876.png"
        }
      }
    },
    publishedAt: "2020-09-01T23:30:22.916Z",
    updatedAt: "2020-09-01T23:30:22.919Z",
    createdAt: "2020-09-01T23:30:22.919Z",
    __v: 0
  },
  {
    id: "5fa136cbccd0e00017b24e9e",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/materials/images/5d2f0095e8368b00282bfc5d_1604400809084.jpeg",
      caption: ""
    },
    categories: ["5d149ad7dcb3ad8a5440ecda", "5d149ad7dcb3ad8a5440ecfb"],
    promote: false,
    type: "lecture",
    title: "Why tech needs the humanities",
    summary:
      "In this talk entrepreneur Eric Berridge shares why tech companies should look beyond STEM graduates for new hires and how people with backgrounds in the arts and humanities can bring creativity and insight to technical workplaces. Source: ted.com",
    content: [
      {
        caption: "",
        body: "",
        id: "5fa136cbccd0e00017b24e9f",
        type: "video",
        source:
          "https://cdn.api.video/vod/vi6hpoKCzqLkVf8ILPZjLcNI/hls/manifest.m3u8"
      }
    ],
    seriesId: null,
    access: {
      price: 0,
      purchase: [],
      id: "5fa136cbccd0e00017b24ea0",
      type: "public"
    },
    userId: "5d2f0095e8368b00282bfc5d",
    user: {
      firstName: "WorkL",
      lastName: "Business Curator",
      profile: {
        jobTitle: "Content Curator",
        picture: {
          small:
            "https://media.engaging.works/production/media/profile/small_db44aaf3-373a-4cd5-af1a-76a76da6925a_1622634407876.png"
        }
      }
    },
    publishedAt: "2020-11-03T10:54:03.018Z",
    updatedAt: "2020-11-03T10:54:03.023Z",
    createdAt: "2020-11-03T10:54:03.023Z",
    __v: 0
  },
  {
    id: "5f929cf1ff650f00175eae62",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/materials/images/5d2f0095e8368b00282bfc5d_1603443938401.jpeg",
      caption: ""
    },
    categories: ["5d149ad7dcb3ad8a5440ecda", "5d149ad7dcb3ad8a5440ed0e"],
    promote: false,
    type: "lecture",
    title: "How Ikea is growing its business while shrinking emissions",
    summary:
      "Discussing new thinking about the lifespan of their products, from the forest to the landfill, Jesper Brodin and Pia Heidenmark Cook of Ingka Group (IKEA share their company's steps towards sustainability. Source: Ted.com\n",
    content: [
      {
        caption: "",
        body: "",
        id: "5f929cf1ff650f00175eae63",
        type: "video",
        source:
          "https://cdn.api.video/vod/vi40qJzVnNMXlsWRhCcxQuVN/hls/manifest.m3u8"
      }
    ],
    seriesId: null,
    access: {
      price: 0,
      purchase: [],
      id: "5f929cf1ff650f00175eae64",
      type: "public"
    },
    userId: "5d2f0095e8368b00282bfc5d",
    user: {
      firstName: "WorkL",
      lastName: "Business Curator",
      profile: {
        jobTitle: "Content Curator",
        picture: {
          small:
            "https://media.engaging.works/production/media/profile/small_db44aaf3-373a-4cd5-af1a-76a76da6925a_1622634407876.png"
        }
      }
    },
    publishedAt: "2020-10-23T09:05:53.525Z",
    updatedAt: "2020-10-23T09:05:53.526Z",
    createdAt: "2020-10-23T09:05:53.526Z",
    __v: 0
  },
  {
    id: "5f7b1240817ede0017eb017a",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/materials/images/5d2f0095e8368b00282bfc5d_1601901091684.jpeg",
      caption: ""
    },
    categories: ["5d149ad7dcb3ad8a5440ecda", "5d149ad7dcb3ad8a5440ed10"],
    promote: false,
    type: "lecture",
    title: "The workforce crisis of 2030 — and how to start solving it now",
    summary:
      "In this data-filled talk, human resources expert Rainer Strack suggests that by 2030 countries ought to look across borders for mobile and willing job seekers. But to do that, they need to start by changing the culture in their businesses. ted.com",
    content: [
      {
        caption: "",
        body: "",
        id: "5f7b1240817ede0017eb017b",
        type: "video",
        source:
          "https://cdn.api.video/vod/vi4c1u3GurFnsaYXiXKy3EzC/hls/manifest.m3u8"
      }
    ],
    seriesId: null,
    access: {
      price: 0,
      purchase: [],
      id: "5f7b1240817ede0017eb017c",
      type: "public"
    },
    userId: "5d2f0095e8368b00282bfc5d",
    user: {
      firstName: "WorkL",
      lastName: "Business Curator",
      profile: {
        jobTitle: "Content Curator",
        picture: {
          small:
            "https://media.engaging.works/production/media/profile/small_db44aaf3-373a-4cd5-af1a-76a76da6925a_1622634407876.png"
        }
      }
    },
    publishedAt: "2020-10-05T12:32:00.086Z",
    updatedAt: "2020-10-05T12:32:00.090Z",
    createdAt: "2020-10-05T12:32:00.090Z",
    __v: 0
  },
  {
    id: "5f58b5e32ab8af001862531d",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/materials/images/5d2f0095e8368b00282bfc5d_1599649224057.jpeg",
      caption: ""
    },
    categories: ["5d80b3e9aabef3ae1a7b971a"],
    promote: false,
    type: "lecture",
    title:
      "How to support witnesses of harassment and build healthier workplaces",
    summary:
      "Memory scientist Julia Shaw explains the psychology of those who witness workplace discrimination and harassment and shares actionable steps companies can take to support and amplify their voices.\n\nSource: ted.com",
    content: [
      {
        caption: "",
        body: "",
        id: "5f58b5e32ab8af001862531e",
        type: "video",
        source:
          "https://cdn.api.video/vod/vi5kGhC89rgg7qIMhnfFEWxk/hls/manifest.m3u8"
      }
    ],
    seriesId: null,
    access: {
      price: 0,
      purchase: [],
      id: "5f58b5e32ab8af001862531f",
      type: "public"
    },
    userId: "5d2f0095e8368b00282bfc5d",
    user: {
      firstName: "WorkL",
      lastName: "Business Curator",
      profile: {
        jobTitle: "Content Curator",
        picture: {
          small:
            "https://media.engaging.works/production/media/profile/small_db44aaf3-373a-4cd5-af1a-76a76da6925a_1622634407876.png"
        }
      }
    },
    publishedAt: "2020-09-09T11:00:51.633Z",
    updatedAt: "2020-09-09T11:00:51.634Z",
    createdAt: "2020-09-09T11:00:51.634Z",
    __v: 0
  },
  {
    id: "5e99d6873640df001719a6a8",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/materials/images/5d2f0095e8368b00282bfc5d_1587140217091.jpeg",
      caption: ""
    },
    categories: [
      "5d149ad7dcb3ad8a5440ecda",
      "5dd6764d0fe419569e0788f5",
      "5d149ad7dcb3ad8a5440ece9"
    ],
    promote: false,
    type: "lecture",
    title: "3 ways to (usefully lose control of your brand",
    summary:
      "Tim Leberecht offers three big ideas about accepting that loss of control, even designing for it -- and using it as an impetus to recommit to your values.\n\nSource: ted.com\n",
    content: [
      {
        caption: "",
        body: "",
        id: "5e99d6873640df001719a6a9",
        type: "video",
        source:
          "https://cdn.api.video/vod/vi3zHNRswGZjSs5H5nBWkVLd/hls/manifest.m3u8"
      }
    ],
    seriesId: null,
    access: {
      price: 0,
      purchase: [],
      id: "5e99d6873640df001719a6aa",
      type: "public"
    },
    userId: "5d2f0095e8368b00282bfc5d",
    user: {
      firstName: "WorkL",
      lastName: "Business Curator",
      profile: {
        jobTitle: "Content Curator",
        picture: {
          small:
            "https://media.engaging.works/production/media/profile/small_db44aaf3-373a-4cd5-af1a-76a76da6925a_1622634407876.png"
        }
      }
    },
    publishedAt: "2020-04-17T16:17:11.277Z",
    updatedAt: "2020-04-17T16:17:11.279Z",
    createdAt: "2020-04-17T16:17:11.279Z",
    __v: 0
  }
]

const webinars = [
  {
    id: "56",
    title:
      "The Happy WorkLife Podcast - Finance & Social Mobility: Steven Cooper CBE on why finance is for everyone and how he progressed through the finance sector",
    date: "",
    coverImage:
      "https://workl-images.s3.eu-west-2.amazonaws.com/Copy+of+Podcast+Cover+(1)+(2).png",
    video:
      "https://workl-images.s3.eu-west-2.amazonaws.com/HWP+Steven+Cooper+FINALmp3+-+William+Fletcher+_+WorkL.mp3",
    summary:
      "WorkL Founder Lord Mark Price speaks to Steven Cooper CBE, Group CEO of Aldermore Bank. Steven discusses some pivotal moments in his career, the attributes needed for a successful career in finance and highlights why finance is an option for people from all backgrounds and education levels. Steven's passion for social mobility plays into his role as Group CEO of Aldermore Bank, emphasising ethical business practice at all levels."
  },
  {
    id: "55",
    title:
      "The Happy WorkLife Podcast - Resilience & Diligence: Liz Jackson MBE on her experience going blind, navigating school, entrepreneurship & building her career",
    date: "",
    coverImage:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/images/Liz-Jackson-MBE.png",
    video:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/audio/WHP-Liz-Jackson.mp3",
    summary:
      "WorkL Founder, Lord Mark Price speaks to Liz Jackson MBE, Sales & Marketing Director at Initium Corporate Finance (formerly BCMS). \
      At the age of 2, Liz was diagnosed with a degenerative eye disorder which meant that by the time she was 25 she was blind. Amazingly at this age, \
      Liz set up an award winning company, Great Guns Marketing, which she ran for 17 years and was awarded an MBE in 2017 by the late Majesty Queen Elizabeth for services to business.\n\
      In this episode, Liz talks about her early school days and the struggles that came with going blind and the impact it had on her education. \
      Liz also shares how she got into her first sales role and transitioned from being an employee to business owner to now working with entrepreneurs and being a co-owner at Initium Corporate Finance."
  },
  {
    id: "54",
    title:
      "Eyes Wide Open - The Secret to Building a Healthy & Happy Workplace - Lord Mark Price",
    date: "",
    coverImage:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/images/1731593951040.jpg",
    video:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/audio/7c39c18adae22aaa4db6fafe211b1fc9.mp3",
    summary:
      "Happiness at work matters, but why? and what does happiness actually mean? How can managers measure it and have more of it? \
    And what happens to organisations when they get happiness right? Host Conor Ryan speaks to WorkL founder Lord Mark Price."
  },
  {
    id: "53",
    title: "WorkL For Business Insights - Business success and risk",
    date: "",
    coverImage:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/images/Business+Insights+%E2%80%93+Graham+Ruddick+-+William+Fletcher+_+WorkL.png",
    video:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/audio/Business+Insights+-+Graham+Ruddick+with+subtitles+-+William+Fletcher+_+WorkL.mp4",
    summary: "With Graham Ruddick, author of Risk Roulette."
  },
  {
    id: "52",
    title:
      "In discussion - Happy Economics: Why the happiest workplaces are the most successful",
    date: "",
    coverImage:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/material-images/Happy+Economics+-+Refreshed+Launch+Posts.png",
    video:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/material-lectures/Happy+Economics+-+Webinar+Video.mp4",
    summary:
      "Lord Mark Price, founder of WorkL and author of Happy Economics is joined by Ann Francke, CEO of the Chartered Management Institute and Geoff McDonald, former Global VP of HR at Unilever."
  },
  {
    id: "51",
    title:
      "The Happy WorkLife Podcast - Breaking Barriers: Joe Seddon on his early years and entrepreneurial path founding Zero Gravity",
    date: "",
    coverImage:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/images/WFB-The-Happy-Worklife-Podcast-Cover-image.png",
    video:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/audio/Joe+Seddon-HW-Podcast-episode-Mixdown-1.mp3",
    summary:
      "WorkL Founder, Lord Mark Price speaks to Joe Seddon, Founder of Zero Gravity, a tech company that helps students from low opportunity areas get into university and access world-class careers. \n\
    Earlier this year Joe was recognised by The Sunday Times in their inaugural Young Power List as one of 25 most inspiring people aged under 30 in the UK & Ireland. Amongst these, Joe has racked up many accolades, \
    being on Forbes 30 under 30 list in 2022 and being honoured in King Charles' Birthday Honours List in 2023. \n\
    In this episode, Joe talks about his early life growing up in Yorkshire, his time at the University of Oxford, \
    coming to identify the barriers that prevented people from getting into top universities and world class careers post graduation. \
    Joe also shares how his early connections with technology fostered his skills and experiences, eventually leading to him founding Zero Gravity."
  },
  {
    id: "50",
    title:
      "WorkL For Business Insights - How businesses can nudge their workforces to embrace AI",
    date: "",
    coverImage:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/images/WFB-Insights-How-businesses-can-nudge-their-workforce-into-adopting-AI-with-Babak-Daemi.png",
    video:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/video/WFB-Insights-How-businesses-can-nudge-their-workforce-into-adopting-AI-with-Babak-Daemi.mp4",
    summary: "With Babak Daemi, Co-founder of PromptAI"
  },
  {
    id: "49",
    title:
      "WorkL For Business Insights - How can HR leaders use AI effectively and ethically?",
    date: "",
    coverImage:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/images/Business_Insights-Nell_Watson-William_Fletcher_WorkL.png",
    video:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/video/Business_Insights-Nell_Watson-WorkL.mp4",
    summary: "With Nell Watson, author of Taming the Machine."
  },
  {
    id: "48",
    title:
      "WorkL For Business Insights - Why is resilience in the workplace important?",
    date: "",
    coverImage:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/images/Business_Insights-Chris_Tamdjidi-William_Fletcher_WorkL.png",
    video:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/video/Business_Insights-Chris_Tamdjidi-WorkL.mp4",
    summary:
      "With Chris Tamdjidi, author of The Resilient Culture and Co-founder of Awaris."
  },
  {
    id: "47",
    title:
      "The Happy WorkLife Podcast - Organic Business: Guy Singh-Watson on his passion for agriculture, early career and founding Riverford",
    date: "",
    coverImage:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/images/Guy-Singh-Watson-Podcast-Cover-William-Fletcher-WorkL.png",
    video:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/audio/WH-Podcast-Guy-Singh-Watson-William-Fletcher-WorkL.mp3",
    summary:
      "WorkL Founder, Lord Mark Price speaks to Guy Singh-Watson, Founder of Riverford, an organic farm and UK-wide organic vegetable box delivery company. \
    Guy is an admired figure in the world of organic farming and has grown Riverford from one man and a wheelbarrow delivering homegrown organic veg to friends, to a national veg box scheme delivering to around 50,000 customers a week. \
    In this episode, Guy talks about his early childhood, his school experiences, making Riverford a completely employee-owned business, the need for honesty and transparency between customers and supermarkets and so much more."
  },
  {
    id: "46",
    title:
      "WorkL For Business Insights - Recognising Extra Discretionary Effort with Lord Mark Price, Founder of WorkL",
    date: "",
    coverImage:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/images/Business-Insights-Mark-Price-Recognising-EDE-Extra-Discretionary-Effort-William-Fletcher_WorkL.png",
    video:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/video/Recognising-Extra-Discrectionary-Effort-Oct-3-2024-William-Fletcher_WorkL.mp4",
    summary: "With Lord Mark Price, Founder of WorkL."
  },
  {
    id: "45",
    title:
      "The Happy WorkLife Podcast - Finding Purpose: Elliott Rae, founder of MFF on his journey to improve the livelihoods of fathers and the discussion around men's mental health",
    date: "",
    coverImage:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/images/happyPodcastElliottRae.png",
    video:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/audio/HW+Podcast+Elliott+Rae+-+William+Fletcher+_+WorkL.mp3",
    summary: ""
  },
  {
    id: "44",
    title: "WorkL For Business Insights - What is Purpose Driven Leadership?",
    date: "",
    coverImage:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/images/WFB+Insights+Videos+-+Logo+-+William+Fletcher+_+WorkL.png",
    video:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/materials/video/WFB+Insights+%E2%80%93+What+is+Purpose+Driven+Leadership+with+Dr+John+Blakey.mp4",
    summary:
      "With Dr John Blakey, leading CEO executive coach and author of three seminal coaching books, Force for Good, The Trusted Executive, and Challenging Coaching."
  },
  {
    id: "43",
    title:
      "The Happy WorkLife Podcast - Happy Economics: Lord Mark Price talks about the upcoming release of his new book",
    date: "",
    coverImage:
      "https://d3us9uuazw4ws8.cloudfront.net/awards/Happy+Economics+Podcast+-+WFB+Cover+image+-+William+Fletcher+_+WorkL.jpg",
    video:
      "https://d3us9uuazw4ws8.cloudfront.net/awards/Happy+Economics+Podcast+episode.mp3",
    summary: ""
  },
  {
    id: "42",
    title:
      "The Happy WorkLife Podcast - Corporate Activism: Sereena Abbassi, Improving culture and inclusion for businesses around the world",
    date: "",
    coverImage:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/business/material-images/Copy of The Happy WorkLife Podcast Cover Template.png",
    video:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/business/material-images/Happy WorkLife Podcast Sereena Abbassi.mp3",
    summary: ""
  },
  {
    id: "41",
    title: "How to enter The Sunday Times Best Places to Work Awards for 2025",
    date: "",
    coverImage: "https://d3us9uuazw4ws8.cloudfront.net/awards/bpwa-WorkL.png",
    video:
      "https://d3us9uuazw4ws8.cloudfront.net/awards/How+to+enter+The+Sunday+Times+Best+Places+to+Work+Awards.+(1)+-+William+Fletcher+_+WorkL.mp4",
    summary: ""
  },
  {
    id: "40",
    title:
      "The Happy WorkLife Podcast - Riding Waves: Konrad Bergström, founder of X Shore and Zound Industries (now Marshall Group) on his entrepreneurial journey",
    date: "",
    coverImage:
      "https://d3us9uuazw4ws8.cloudfront.net/awards/WFB+Konrad+Bergstrom+Podcast+Video+(Edited)+(4)+-+William+Fletcher+_+WorkL.png",
    video:
      "https://d3us9uuazw4ws8.cloudfront.net/awards/WFB+-+Konrad+Bergstrom+Podcast+Video.mp4",
    summary: ""
  },
  {
    id: "39",
    title:
      "In discussion - The 4-Day Work Week: How will it impact the future of work?",
    date: "",
    coverImage:
      "https://d3us9uuazw4ws8.cloudfront.net/awards/The+4-Day+Work+Week+How+will+it+impact+the+future+of+work.jpg",
    video:
      "https://d3us9uuazw4ws8.cloudfront.net/awards/In+discussion+-+The+4-Day+Work+Week+How+will+it+impact+the+future+of+work.mp4",
    summary: ""
  },
  {
    id: "38",
    title:
      "Business Leader - Lord Mark Price: Waitrose, working in government and happiness at work today",
    date: "",
    coverImage:
      "https://d3us9uuazw4ws8.cloudfront.net/awards/Waitrose%2C+working+in+government+and+happiness+at+work+today.jpg",
    video:
      "https://d3us9uuazw4ws8.cloudfront.net/awards/Waitrose%2C+working+in+government+and+happiness+at+work+today.mp3",
    summary: ""
  },
  {
    id: "37",
    title:
      "The Happy WorkLife Podcast - A Career that has Bloomed: Nelly Hall reflects on her creative career as she prepares for the world's most prestigious flower show",
    date: "",
    coverImage:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/podcasts/The Happy WorkLife Podcast Cover Nelly Hall.png",
    video:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/podcasts/WFB Nelly Hall Podcast Video.mp4",
    summary: ""
  },
  {
    id: "36",
    title:
      "The Happy WorkLife Podcast - A Recipe for Success: James Perry, Co-founder of COOK argues why businesses must value their workforce",
    date: "",
    coverImage:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/podcasts/The+Happy+WorkLife+Podcast+-+James+Perry+-+William+Fletcher+_+WorkL.png",
    video:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/podcasts/James+Perry+Podcast+Video.mp4",
    summary: ""
  },
  {
    id: "35",
    title:
      "The Happy WorkLife Podcast - Breaking News & Beyond: Kamal Ahmed reflects on his journalistic career and how burnout made him reevaluate his life",
    date: "",
    coverImage:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/podcasts/Copy+of+Kamal+Ahmed+Podcast+Video-3+-+William+Fletcher+_+WorkL.jpg",
    video:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/podcasts/Kamal+Ahmed+Podcast+Video.mp4",
    summary: ""
  },
  {
    id: "34",
    title:
      "The HR Grapevine Podcast - Are you protecting employee equity amid artificial intelligence adoption?",
    date: "",
    coverImage:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/podcasts/s16-ep7-podcast-ai-adoption.jpg",
    video:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/podcasts/HR+Grapevine+podcast+-+William+Fletcher+_+WorkL.mp3",
    summary: ""
  },
  {
    id: "33",
    title: "In discussion - The Future of HR and AI",
    date: "September 13, 2023",
    coverImage:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/business/materials/files/The+Future+of+HR+and+AI+cover+image.png",
    video:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/business/materials/files/In+discussion+Future+of+HR+and+Ai.mp4",
    summary:
      "WorkL for Business founder Lord Mark Price is joined by Andi Britt, Senior Partner, Talent Transformation, IBM Consulting and Dr Selin Nugent, Assistant Director Social Science Research for the Institute for Ethical AI, Oxford Brookes University for a discussion on the Future of HR and AI."
  },
  {
    id: "32",
    title:
      "In discussion - How can you identify, mitigate and reduce Flight Risk in your organisation?",
    date: "May 01, 2023",
    coverImage:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/business/materials/files/Webinar+-+Flight+Risk+Presentation+.png",
    video:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/business/materials/files/Flight+Risk+Webinar+-+April+2023.mp4",
    summary:
      "WorkL for Business founder Lord Mark Price is joined by Peter Cheese, CEO of the CIPD and Tera Allas, Director of Research and Economics at McKinsey & Co to discuss how HR and business leaders can identify, mitigate and reduce Flight Risk in their organisations."
  },
  {
    id: "31",
    title: "In discussion - Wellbeing and the Great Reset.",
    date: "February 01, 2023",
    coverImage:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/business/material-images/WorkL+January+2023+Webinar+video+cover+slide.jpg",
    video:
      "https://d3us9uuazw4ws8.cloudfront.net/production/media/marketing/Workl+Webinar+Jan+2023.mp4",
    summary:
      "WorkL for Business founder Lord Mark Price is joined by Dr Jasmine Kelland, Lecturer in Human Resources Management, Plymouth University and Chris Cummings, CEO, Wellbeing at Work."
  }
]

const research = [
  {
    id: "5e0f4ec8778d280010b09961",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/materials/images/5b056c66bf4620720c1ac717_1578061512566.jpg",
      caption: ""
    },
    categories: [
      "5d149ad7dcb3ad8a5440ecda",
      "5d149ad7dcb3ad8a5440ecff",
      "5d149ad7dcb3ad8a5440ecdf"
    ],
    promote: false,
    type: "research",
    user: {
      firstName: "WorkL",
      lastName: "Business Curator",
      profile: {
        jobTitle: "Content Curator",
        picture: {
          small:
            "https://media.engaging.works/production/media/business/material-images/WFB_logo_new.jpg"
        }
      }
    },
    title: "Introduction to mentally healthy workplaces",
    summary: "",
    content: [
      {
        caption: "",
        body: "",
        id: "5e0f4ec8778d280010b09992",
        type: "document",
        source:
          "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/materials/files/Introduction+to+mentally+healthy+workplaces.pdf"
      }
    ],
    seriesId: null,
    access: {
      price: 0,
      purchase: [],
      id: "5e0f4ec8778d280010b09993",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-01-03T14:25:12.631Z",
    updatedAt: "2020-01-03T14:25:12.636Z",
    createdAt: "2020-01-03T14:25:12.636Z"
  },
  {
    id: "5e0f4ec8778d280010b09962",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/wfbcover+-+WorkL+for+Business+WorkLife+News+August+2023.jpg",
      caption: ""
    },
    categories: [
      "5d149ad7dcb3ad8a5440ecda",
      "5d149ad7dcb3ad8a5440ecff",
      "5d149ad7dcb3ad8a5440ecdf"
    ],
    promote: false,
    type: "research",
    user: {
      firstName: "WorkL",
      lastName: "Business Curator",
      profile: {
        jobTitle: "Content Curator",
        picture: {
          small:
            "https://media.engaging.works/production/media/business/material-images/WFB_logo_new.jpg"
        }
      }
    },
    title:
      "WorkLife Business News August 2023 - How to embrace AI and the future of jobs and HR",
    summary: "",
    content: [
      {
        caption: "",
        body: "",
        id: "5e0f4ec8778d280010b09993",
        type: "document",
        source:
          "https://media.engaging.works/production/media/business/materials/files/WorkL+for+Business+WorkLife+News+August+2023.pdf"
      }
    ],
    seriesId: null,
    access: {
      price: 0,
      purchase: [],
      id: "5e0f4ec8778d280010b09953",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-01-03T14:25:12.631Z",
    updatedAt: "2020-01-03T14:25:12.636Z",
    createdAt: "2020-01-03T14:25:12.636Z"
  },
  {
    id: "5e0f4ec8778d280010b09991",
    coverImage: {
      source:
        "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/materials/files/Happiness+at+work.pdf",
      caption: ""
    },
    categories: [
      "5d149ad7dcb3ad8a5440ecda",
      "5d149ad7dcb3ad8a5440ecff",
      "5d149ad7dcb3ad8a5440ecdf"
    ],
    promote: false,
    type: "research",
    user: {
      firstName: "WorkL",
      lastName: "Business Curator",
      profile: {
        jobTitle: "Content Curator",
        picture: {
          small:
            "https://media.engaging.works/production/media/business/material-images/WFB_logo_new.jpg"
        }
      }
    },
    title: "Happiness at Work",
    summary: "Lord Mark Price gives his tips to Liz Earle Wellbeing magazine.",
    content: [
      {
        caption: "",
        body: "",
        id: "5e0f4ec8778d280010b09992",
        type: "document",
        source:
          "https://media.engaging.works/production/media/materials/files/5b056c66bf4620720c1ac717_1578061511644.pdf"
      }
    ],
    seriesId: null,
    access: {
      price: 0,
      purchase: [],
      id: "5e0f4ec8778d280010b09993",
      type: "public"
    },
    userId: "5b056c66bf4620720c1ac717",
    publishedAt: "2020-01-03T14:25:12.631Z",
    updatedAt: "2020-01-03T14:25:12.636Z",
    createdAt: "2020-01-03T14:25:12.636Z"
  }
]

export { articles, lectures, webinars, research }
