import React from "react"
import { Flex, Text } from "@engaging-tech/components"
import Questions from "./components/Questions"
import CircularProgressComponent from "./components/CircularProgressComponent"
import ReportCard from "../components/ReportCard"

const splitByCapital = text => {
  // Adding "++" will not add spaces. for example if the input is;
  // customStepName: "AdittionalA++GBarrQuestions", the output is; Adittional AG Barr Questions
  return text
    .split(/(?=[A-Z])/)
    .join(" ")
    .replace("++ ", "")
}

const BespokeStepView = ({ bespokeSteps }) => {
  return (
    <>
      {bespokeSteps.map(({ step, questions, score }) => (
        <ReportCard
          flexDirection="column"
          width={1 / 1}
          heading="Your Bespoke Step"
          metaProps={{
            marginBottom: "0px"
          }}
        >
          <Text>{splitByCapital(step)}</Text>
          <Flex>
            <CircularProgressComponent score={score} />
            <Questions questions={questions} />
          </Flex>
        </ReportCard>
      ))}
    </>
  )
}

export default BespokeStepView
