import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const pageDetails = {
  heading: "Purchasing your World’s Happiest Workplaces Logo and Certificate.",
  image:
    "https://media.engaging.works/production/media/siteImages/happies_badge.png",
  text: [
    "Congratulations on being recognised by WorkL as a World’s Happiest Workplace 2025!",
    "You can promote this by downloading your logo and certificate.",
    "To purchase your logo and certificate, select the logos you wish to purchase and your organisation size then click Next."
  ]
}

const Image = styled("img")`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  vertical-align: middle;
  font-style: italic;
  @media screen and (max-width: 330px) {
    margin: auto;
  }
`

const ViewBuyOption = () => {
  return (
    <Flex width={1 / 1} justifyContent="center">
      <Flex
        width={2 / 3}
        alignItems="center"
        flexDirection="column"
        maxWidth="948px"
      >
        <Text
          width="fit-content"
          fontSize={[5, 7]}
          fontWeight={600}
          mt={6}
          color="secondary.0"
          textAlign="center"
        >
          {pageDetails.heading}
        </Text>
        <Flex
          width={1 / 1}
          mt={[2, 3]}
          justifyContent="space-between"
          flexDirection={["column-reverse", "row"]}
        >
          <Flex flexDirection="column" width={[1 / 1, 6 / 10]}>
            {pageDetails.text.map(entry => (
              <Text
                key={entry}
                mt={[5, 3]}
                color="secondary.0"
                fontSize={[3, 4]}
                width={1 / 1}
                textAlign={["center", "left"]}
              >
                {entry}
              </Text>
            ))}
          </Flex>
          <Flex
            width={[1 / 1, 3 / 10]}
            height={["150px", "190px"]}
            justifyContent={["center", "flex-end"]}
            mt={3}
          >
            <Image src={pageDetails.image} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ViewBuyOption
