/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable no-template-curly-in-string */
import {
  Box,
  Button,
  Flex,
  Paragraph,
  Spinner,
  Text
} from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import DOMPurify from "dompurify"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

import LIST_PURCHASES from "../../../graphql/queries/listPurchases"
import useBusinessAppQuery from "../../../hooks/useBusinessAppQuery"
import { PaddedBox } from "../../../pages/awards/EnterAwards"
import addParams from "../../../utils/addParams"
import {
  getHasSliceAndDiceLicence,
  hasAnAwardsSurvey
} from "../../account/store/account.selectors"
import ErrorState from "../../business-information/components/error-state"
import SliceAndDiceUpsell from "../../sliceAndDice/components/SliceAndDiceUpsell"
import { feedbackDialogOpen } from "../../ui/store/ui.actions"

const Img = styled("img")`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  vertical-align: middle;
  font-style: italic;
`
const Divider = styled(Box)`
  width: 100%;
  border-bottom: 1px solid lightgrey;
  margin-top: 25px;
  margin-bottom: 25px;
`

const FlexCustom = styled(Flex)`
  li:before {
    content: "";
    margin-left: -10px;
    font-size: 14px;
  }
  li {
    margin-left: 20px;
  }
  flex-direction: column;
`
// Sanitize HREF to have workl domain
const sanitizeHref = text => {
  if (text.includes("href=")) {
    const regex = new RegExp("app.business.workl.co")
    return regex.test(text) ? text : ""
  }
  return text
}

const PurchaseEntry = ({
  name,
  text,
  faq,
  welcomePack,
  showGoToLiveSurveys,
  reportId
}) => {
  const router = useRouter()
  const paths = usePaths()

  const showFaqAndWelcomePack = faq && welcomePack
  const showLiveSurveysButton = showFaqAndWelcomePack || showGoToLiveSurveys

  const goToReportUpgraded = () => {
    window.localStorage.removeItem("surveyIdUpgrade")
    router.navigate(
      paths.reports.engagementSurveys.index + `/${reportId}?upgraded=true`
    )
  }

  return (
    <>
      <FlexCustom width={"100%"} mb={2}>
        <Text color="secondary.0" fontSize={[4, 5]} fontWeight={600} mb={3}>
          {name}
        </Text>
        {text?.map(entry => (
          <Paragraph
            fontSize={[3, 4]}
            mt={[2, 1]}
            color="secondary.0"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(entry, {
                USE_PROFILES: { html: true },
                ALLOWED_ATTR: ["href", "target", "rel", "style", "mailto"],
                ADD_URI_SAFE_ATTR: ["mailto"],
                ALLOW_UNKNOWN_PROTOCOLS: true
              })
            }}
          ></Paragraph>
        ))}
        {showFaqAndWelcomePack || showLiveSurveysButton || reportId ? (
          <Flex
            width={1 / 1}
            flexWrap="wrap"
            mt={3}
            flexDirection={["column", "row"]}
            justifyContent={["center", "space-between"]}
            alignItems={["center"]}
            px={[0, 0, "3%"]}
          >
            {showFaqAndWelcomePack && (
              <>
                <Button
                  bg="secondary.0"
                  color="light.0"
                  mt={[3, 0]}
                  width={["300px", "57%"]}
                  onClick={() => window.open(welcomePack)}
                >
                  Download Welcome Pack
                </Button>
                <Button
                  bg="secondary.0"
                  color="light.0"
                  mt={[3, 0]}
                  width={["300px", "40%"]}
                  onClick={() => window.open(faq)}
                >
                  Download FAQs
                </Button>
              </>
            )}
            {showLiveSurveysButton && (
              <Button
                bg="primary.0"
                mt={[3, 2, 2]}
                color="light.0"
                width={["300px", 1 / 1]}
                onClick={() => router.navigate(paths.surveys.liveSurveys)}
              >
                Continue to Live Surveys Dashboard
              </Button>
            )}
            {reportId && (
              <Button
                bg="primary.0"
                mt={[3, 2, 2]}
                color="light.0"
                width={["300px", 1 / 1]}
                onClick={goToReportUpgraded}
              >
                VIEW YOUR ENHANCED DATA REPORT
              </Button>
            )}
          </Flex>
        ) : null}
      </FlexCustom>
      <Divider />
    </>
  )
}

const Success = ({ paymentId, showGoToLiveSurveys, reportId }) => {
  const dispatch = useDispatch()
  const paths = usePaths()
  const hasSliceAndDiceLicence = useSelector(getHasSliceAndDiceLicence)
  const hasAnAwardSurvey = useSelector(hasAnAwardsSurvey)
  useEffect(() => {
    addParams(hasSliceAndDiceLicence, "slice-and-dice", "inactive")
  }, [])

  useEffect(() => {
    dispatch(feedbackDialogOpen({ paymentId }))
  }, [])

  const { data, isLoading, error } = useBusinessAppQuery(LIST_PURCHASES, {
    variables: { paymentId }
  })

  if (error) return <ErrorState />
  if (isLoading) return <Spinner justifyContent="center" color="primary.0" />

  return (
    <PaddedBox pt={6}>
      <Flex
        width={1 / 1}
        justifyContent="space-between"
        flexDirection={["column", "row"]}
      >
        <Flex width={[1 / 1, 20 / 40]} flexDirection="column">
          {data?.listPurchases?.map(({ name, text, faq, welcomePack }) => (
            <PurchaseEntry
              key={name}
              name={name}
              text={text}
              faq={faq}
              welcomePack={welcomePack}
              showGoToLiveSurveys={showGoToLiveSurveys}
              reportId={reportId}
            />
          ))}
        </Flex>
        <Flex
          width={[1 / 1, 18 / 40]}
          alignItems="center"
          flexDirection="column"
        >
          <Flex bg="#F0F8FF" py={3} width={1 / 1} justifyContent="center">
            <Img
              src="https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/material-images/4+-+You're+In+1.png"
              alt="success-image"
              width="35%"
            />
          </Flex>
          <Text width="fit-content" fontSize={3} mt={3}>
            If you need any support please email support@workl.com or phone
            (+44) 20 4576 1730
          </Text>
        </Flex>
      </Flex>
      {hasAnAwardSurvey && !hasSliceAndDiceLicence && <SliceAndDiceUpsell />}
      {!hasAnAwardSurvey && hasSliceAndDiceLicence && (
        <Flex width={1 / 1} justifyContent="center">
          <Text width="fit-content" mt={3}>
            <b>
              <u>
                <a href={paths.index}>Click here</a>
              </u>{" "}
              to return to the WorkL for Business homepage
            </b>
          </Text>
        </Flex>
      )}
    </PaddedBox>
  )
}

export default Success
