import { gql } from "@apollo/client"

const GET_ACTION_PLAN_PREVIEW = gql`
  query previewActionPlan($actionPlanId: String!) {
    previewActionPlan(actionPlanId: $actionPlanId) {
      body {
        _id
        actionPlanSteps {
          actionPlanId
          createdAt
          default
          id
          questions {
            body {
              questions {
                answers
                id
                title
              }
              resources {
                description
                mediaType
                title
                url
              }
              suggestedActions
              suggestedPhrases
              description
            }
            sixStepsQuestionId
            title
          }
          title
          type
          updatedAt
        }
        createdAt
        default
        organisationId
        planType
        steps
        surveyCount
        surveyTitle
        title
        updatedAt
      }
      message
      status
    }
  }
`

export default GET_ACTION_PLAN_PREVIEW
