const research = [
  {
    id: "22",
    title: "WorkLife Business News December 2024 - Supporting employees during winter and preparing for the new year",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2024-12-18T13:00:00.000Z",
    coverImage: {
      source:
        "https://media.engaging.works/awards/WorkLife+Business+News+Photo.png",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "WorkLife Business News December 2024 - Supporting employees during winter and preparing for the new year",
      link: "https://www.flipsnack.com/workl/workl-for-business-worklife-business-news-december-2024/full-view.html"
    }
  },
  {
    id: "21",
    title: "Global Workplace Report 2024, powered by WorkL",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2024-12-03T17:20:00.000Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/Global+Workplace+Report+2024%2C+powered+by+WorkL+-+Cover+Image+2024.png",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "Global Workplace Report 2024, powered by WorkL",
      link: "https://www.flipsnack.com/workl/global-workplace-report-2024/full-view.html"
    }
  },
  {
    id: "20",
    title:
      "WorkLife Business News November 2024 - Communication and Information Sharing",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2024-11-21T17:20:00.000Z",
    coverImage: {
      source:
        "https://media.engaging.works/awards/WFBCoverNov24.jpg",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "WorkLife Business News November 2024 - Communication and Information Sharing",
      link: "https://www.flipsnack.com/workl/workl-for-business-worklife-news-november-2024/full-view.html"
    }
  },
  {
    id: "19",
    title:
      "WorkLife Business News October 2024 - Attracting and Retaining Talent",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2024-10-16T16:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/awards/WFBcoverOct24+-+William+Fletcher+_+WorkL.jpg",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "WorkLife Business News October 2024 - Attracting and Retaining Talent",
      link: "https://www.flipsnack.com/workl/workl-for-business-worklife-news-october-2024/full-view.html"
    }
  },
  {
    id: "18",
    title: "WorkLife Business News August 2024 - Cultivating Company Culture",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2024-08-23T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/awards/WorkLife_Aug2024.png",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "WorkLife Business News August 2024 - Cultivating Company Culture",
      link: "https://www.flipsnack.com/workl/workl-for-business-worklife-news-august-2024/full-view.html"
    }
  },
  {
    id: "17",
    title:
      "WorkLife Business News July 2024 - Enhancing Engagement: The Benefits of Reward & Recognition",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2024-07-23T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/awards/unnamed+(8)+-+William+Fletcher+_+WorkL.jpg",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "WorkLife Business News July 2024 - Enhancing Engagement: The Benefits of Reward & Recognition",
      link: "https://www.flipsnack.com/workl/workl-for-business-worklife-news-july-2024/full-view.html"
    }
  },
  {
    id: "16",
    title:
      "WorkLife Business News June 2024 - Flexible Futures: Mastering the art of agile work environments",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2024-07-12T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/awards/WFBcoverJune+-+William+Fletcher+_+WorkL.jpg",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "WorkLife Business News June 2024 - Flexible Futures: Mastering the art of agile work environments",
      link: "https://www.flipsnack.com/workl/workl-for-business-worklife-news-june-2024/full-view.html"
    }
  },
  {
    id: "15",
    title:
      "WorkLife Business News May 2024 - Unlocking Employee Potential: Learning and development in the workplace",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2024-05-24T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/WFBcoverMay24.jpg",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "WorkLife Business News May 2024 - Unlocking Employee Potential: Learning and development in the workplace",
      link: "https://www.flipsnack.com/workl/workl-for-business-worklife-news-may-2024/full-view.html"
    }
  },
  {
    id: "14",
    title:
      "WorkLife Business News April 2024 - Navigating employee mental health in the modern workplace",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2024-03-20T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/WFBCoverApril+-+William+Fletcher+_+WorkL.jpg",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "WorkLife Business News March 2024 - Collaboration & Teamwork: Unity creates strength, innovation and business success",
      link: "https://www.flipsnack.com/workl/workl-for-business-worklife-news-april-2024-hp8bjin7t9/full-view.html"
    }
  },
  {
    id: "13",
    title:
      "WorkLife Business News March 2024 - Collaboration & Teamwork: Unity creates strength, innovation and business success",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2024-03-20T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/WFBcover_Mar24.jpg",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "WorkLife Business News March 2024 - Collaboration & Teamwork: Unity creates strength, innovation and business success",
      link: "https://www.flipsnack.com/workl/workl-for-business-worklife-news-march-2024/full-view.html"
    }
  },
  {
    id: "12",
    title:
      "WorkLife Business News February 2024 - Diversity Drives: Inclusion, Insights and Innovation",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2024-02-28T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/WFBCover_feb.jpg",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "WorkLife Business News February 2024 - Diversity Drives: Inclusion, Insights and Innovation",
      link: "https://www.flipsnack.com/workl/workl-for-business-worklife-news-february-2024/full-view.html"
    }
  },
  {
    id: "11",
    title: "WorkLife Business News January 2024 - 2024: Navigating the Future",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2024-01-25T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/WFBJANCOVER.jpg",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "WorkLife Business News January 2024 - 2024: Navigating the Future",
      link: "https://www.flipsnack.com/workl/workl-for-business-worklife-news-january-2024/full-view.html"
    }
  },
  {
    id: "10",
    title:
      "WorkLife Business News December 2023 - Strategies for employee management in winter and new year preparation",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2023-11-13T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/WFB_cover_dec_2023.jpg",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "WorkLife Business News November 2023 - Building resilience at work, change management & overcoming failure",
      link: "https://www.flipsnack.com/workl/workl-for-business-worklife-news-december-2023/full-view.html"
    }
  },
  {
    id: "9",
    title:
      "WorkLife Business News November 2023 - Building resilience at work, change management & overcoming failure",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2023-11-13T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/WFBNovCover.jpg",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "WorkLife Business News November 2023 - Building resilience at work, change management & overcoming failure",
      link: "https://media.engaging.works/production/media/business/materials/files/WorkL+for+Business+WorkLife+News+November+2023.pdf"
    }
  },
  {
    id: "8",
    title: "The Future of HR and AI - Webinar Presentation",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2023-09-22T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/materials/files/Final+Edition+Webinar+Presentation+-+The+Future+of+HR+and+AI.png",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "The Future of HR and AI - Webinar Presentation",
      link: "https://media.engaging.works/production/media/business/materials/files/Final+Edition+Webinar+Presentation+-+The+Future+of+HR+and+AI.pdf"
    }
  },
  {
    id: "7",
    title: "Employee Experience Trends Report - Q2 2023",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2023-07-20T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/Employee+Experience+Trends+Report+-+Q2+2023.png",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "Employee Experience Trends Report - Q2 2023.pdf",
      link: "https://media.engaging.works/production/media/business/materials/files/Employee+Experience+Trends+Report+-+Q2+2023.pdf"
    }
  },
  {
    id: "6",
    title: "Employee Experience Trends Report - Q1 2023",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2023-05-01T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/Employee+Experience+Trends+Report++Q1+2023+(1).png",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "Employee Experience Trends Report - Q1 2023.pdf",
      link: "https://media.engaging.works/production/media/business/materials/files/Employee+Experience+Trends+Report++Q1+2023+(1)+(4)+(1).pdf"
    }
  },
  {
    id: "5",
    title: "Transportation and Logistics Insight Report - Q1 2023",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2023-05-01T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/Industry+Report+(Transportation+and+Logistics).jpg",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "Transportation and Logistics Insight Report - Q1 2023",
      link: "https://media.engaging.works/production/media/business/materials/files/Industry+Report+(Transportation+and+Logistics).pdf"
    }
  },
  {
    id: "4",
    title: "Hospitality Insight Report - Q1 2023",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2023-05-01T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/Industry+Report+(Hospitality).jpg",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "",
      link: "https://media.engaging.works/production/media/business/materials/files/Industry+Report+(Hospitality).pdf"
    }
  },
  {
    id: "3",
    title: "Technology Insight Report - Q1 2023",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2023-05-01T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/Industry+Report+(Technology).jpg",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "Technology Insight Report - Q1 2023",
      link: "https://media.engaging.works/production/media/business/materials/files/Industry+Report+(Technology).pdf"
    }
  },
  {
    id: "2",
    title: "Retail Insight Report - Q1 2023",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2023-05-01T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/Industry+Report+(Retail).jpg",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "Retail Insight Report - Q1 2023",
      link: "https://media.engaging.works/production/media/business/materials/files/Industry+Report+(Retail).pdf"
    }
  },

  {
    id: "1",
    title: "Employee Experience Report 2022",
    categories: ["5d149ad7dcb3ad8a5440ecda"],
    publishedAt: "2022-11-01T11:38:27.696Z",
    coverImage: {
      source:
        "https://media.engaging.works/production/media/business/material-images/Employee+Experience+Report+2022.pptx.png",
      caption: ""
    },
    summary: "",
    type: "research",
    article: "",
    pdf: {
      name: "Employee Experience Report 2022.pdf",
      link: "https://media.engaging.works/production/media/business/materials/files/Employee+Experience+Report+2022+(1).pdf"
    }
  }
]

export default research
